import { ButtonActionProp } from "./DropdownMenuButton";

export function MenuButton({ label, action, inverted }: ButtonActionProp) {
  return (
    <div
      style={{
        borderRadius: "18px",
        borderColor: "green",
        borderStyle: "solid",
        backgroundColor: inverted ? "#00000023" : "green",

        backdropFilter: inverted ? " blur(18px)" : "none",
        color: "white",
        paddingLeft: "28px",
        paddingRight: "28px",
        marginTop: "10px",
        height: "fit-content",
        fontSize: "16px",
      }}
    >
      <button
        style={{
          borderRadius: "10px",
          borderColor: "transparent",
          backgroundColor: "transparent",
          color: "white",
          width: "100%",
          padding: 0,
          margin: 0,

          fontSize: "18px",
          lineHeight: "28px",
        }}
        onClick={action}
      >
        {label}
      </button>
    </div>
  );
}
