import { useEffect, useRef, useState } from "react";
import { registerNewUser } from "../../../services/webservices";
import { Messager } from "../MainPage";
import { RegisterData } from "../PaginaInicio";
import { CheckBox } from "./CheckBox";
import { DateSelector } from "./DateSelector";
import { RoundInput } from "./RoundInput";
import { RoundSelect } from "./RoundSelect";
import { CloseOutside } from "./Util";
import { RoundWhiteInput } from "./RoundWhiteInput";

export interface Props {
  setModalOpen: React.Dispatch<React.SetStateAction<boolean>>;
  setLoginOpen: React.Dispatch<React.SetStateAction<boolean>>;
  registerData: RegisterData | undefined;
}

export function RegisterTabPart2({
  setModalOpen,
  setLoginOpen,
  registerData,
}: Props) {
  const modalContainerRef = useRef<HTMLDivElement | null>(null);
  const [loading, setLoading] = useState<boolean>(false);
  CloseOutside(modalContainerRef, setModalOpen);

  return (
    <div
      style={{
        zIndex: "2000",
        position: "absolute",
        top: "0px",
        right: "0px",
        width: "400px",
        height: "800px",
        backgroundColor: "#22CC2288",
        overflow: "hidden",
      }}
      ref={modalContainerRef}
    >
      <div style={{ position: "relative" }}>
        <div
          className="blurry"
          style={{
            width: "400px",
            height: "800px",
            borderColor: "transparent",
            position: "absolute",
            zIndex: 0,
          }}
        ></div>
        <div
          style={{
            zIndex: 0,
            position: "absolute",
            width: "100%",
            color: "white",
            paddingTop: "50px",
            fontSize: "24px",
            textAlign: "center",
          }}
        >
          <div style={{ paddingBottom: "20px" }}>Criar Conta</div>
          <img
            src="profilepic.png"
            style={{ cursor: "pointer" }}
            onClick={() => {
              console.log("click");
            }}
          />
          <RoundWhiteInput label="Apelido" type="text" id="apelido" />
          <RoundSelect
            label="Sexo"
            options={["Masculino", "Feminino", "Não informar"]}
            values={["M", "F", "N"]}
            id="sexo"
          />
          <RoundWhiteInput label="Localização" type="text" id="local" />
          <RoundWhiteInput label="Ocupação" type="text" id="ocupacao" />
          <div
            style={{
              paddingTop: "20px",
              paddingLeft: "60px",
              paddingBottom: "20px",
            }}
          >
            <CheckBox
              label="Declaro que li e compreendi os "
              link="termos de uso."
              linkAction={() => {
                window
                  .open(
                    window.location.href.substring(
                      0,
                      window.location.href.indexOf("?")
                    ) + "?pag=6",
                    "_blank"
                  )
                  ?.focus();
              }}
              id="termosdeuso"
            />
            <CheckBox
              label="Concordo com a "
              link="política de privacidade."
              linkAction={() => {
                window
                  .open(
                    window.location.href.substring(
                      0,
                      window.location.href.indexOf("?")
                    ) + "?pag=6",
                    "_blank"
                  )
                  ?.focus();
              }}
              id="privacidade"
            />
            <CheckBox
              label="Aceito receber novidades e notícias."
              link=""
              linkAction={() => {}}
              id="marketing"
            />
          </div>
          <button
            style={{
              borderRadius: "25px",
              padding: "10px",
              width: "280px",
              backgroundColor: "black",
              color: "white",
              fontWeight: "bold",
              fontSize: "20px",
              borderColor: "lightgray",
              borderStyle: "solid",
              borderWidth: "1px",
            }}
            disabled={loading}
            onClick={() => {
              const termos = (
                document.getElementById("termosdeuso") as HTMLInputElement
              ).checked;
              const privacidade = (
                document.getElementById("privacidade") as HTMLInputElement
              ).checked;
              const marketing = (
                document.getElementById("marketing") as HTMLInputElement
              ).checked;

              const username = (
                document.getElementById("apelido") as HTMLInputElement
              ).value;
              const sex = (document.getElementById("sexo") as HTMLInputElement)
                .value;
              const occupation = (
                document.getElementById("ocupacao") as HTMLInputElement
              ).value;
              const location = (
                document.getElementById("local") as HTMLInputElement
              ).value;

              if (
                termos &&
                privacidade &&
                username &&
                sex &&
                occupation &&
                location
              ) {
                const params = {
                  name: registerData?.nome,
                  lastName: registerData?.sobrenome,
                  email: registerData?.email,
                  password: registerData?.senha,
                  phone: registerData?.telefone,
                  cpf: registerData?.cpf,
                  birthDate:
                    registerData?.dia +
                    "-" +
                    registerData?.mes +
                    "-" +
                    registerData?.ano,
                  username: username,
                  sex: sex,
                  occupation: occupation,
                  location: location,
                  acceptContact: marketing,
                };
                registerNewUser(params, setLoading, Messager.message).then(
                  (resp) => {
                    if (resp !== null) {
                      Messager.message(
                        "Sucesso!",
                        "Usuário Cadastrado Com Sucesso!"
                      );
                    }
                  }
                );
              }
            }}
          >
            {" "}
            {loading ? "Loading..." : "Criar Conta"}
          </button>
          <hr
            style={{ width: "200px", height: "1px", borderStyle: "none" }}
            color="#CCCCCCAA"
          />
          <div style={{ fontSize: "15px" }}>
            Já possui uma conta?{" "}
            <button
              style={{
                backgroundColor: "transparent",
                borderStyle: "none",
                color: "white",
                fontWeight: "bold",
              }}
              onClick={() => {
                setModalOpen(false);
                setLoginOpen(true);
              }}
            >
              Entre Agora
            </button>
          </div>
        </div>
      </div>
    </div>
  );
}
