interface Props {
  title: string;
  icon: any;
  line: string;
}

export function WhiteTag({ title, icon, line }: Props) {
  return (
    <div
      style={{
        backgroundColor: "white",
        borderRadius: "10px",
        width: "300px",
        alignContent: "center",
        alignItems: "center",
        textAlign: "center",
        marginBottom:"10px",
      }}
    >
      <div>
        <div
          style={{
            color: "#419802",
            display: "flex",
            alignItems: "center",
            alignContent: "center",
            alignSelf: "center",
            justifyContent: "center",
            fontWeight: "bold",
            paddingTop: "30px",
            fontSize: "15px",
          }}
        >
          <img src={icon} style={{ paddingRight: "10px" }} /> {title}
        </div>
      </div>
      <hr color="green" style={{ width: "50%" }}></hr>
      <div
        style={{
          paddingBottom: "15px",
          fontSize: "13px",
          paddingLeft: "20px",
          paddingRight: "20px",
        }}
      >
        {line}
      </div>
    </div>
  );
}
