import { setProfileImage } from "../../../services/login";
import { Messager } from "../MainPage";

export function loadImage() {
  var input = document.createElement("input");
  input.type = "file";

  input.onchange = (e) => {
    // getting a hold of the file reference
    var file = e?.target?.files[0];

    // setting up the reader
    var reader = new FileReader();
    reader.readAsBinaryString(file, "UTF-8");

    // here we tell the reader what to do when it's done reading...
    reader.onloadend = (readerEvent) => {
      var content = readerEvent.target.result; // this is the content!
      document.getElementById("profilePic").src = content;
      setProfileImage(file, (ans) => {
        console.log(ans);
        if (ans.message === "Foto atualizada com sucesso") {
          Messager.message(
            "Sucesso!",
            "Foto atualizada com sucesso!",
            undefined,
            () => {
              window.location.href = window.location.href;
            }
          );
        }
      });
    };
  };

  input.click();
}
