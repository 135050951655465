import { useRef, useState } from "react";
import "./RoundWhiteInput.css";

export interface Props {
  label: string;
  type: string;
  id: string;
  subtext?: string;
  subtextsize?: string;
  defaultValue?: number | string;
  width?: string;
  fontSize?: number;
  labelfontSize?: number;
  length?: number;
  fontColor?: string;
  borderColor?: string;
  backgroundColor?: string;
  validation?: (value: any) => string | null;
  mask?: (value: any) => string;
}

export function RoundWhiteInput({
  label,
  type,
  id,
  subtext,
  subtextsize,
  defaultValue,
  width = "280px",
  fontSize = 14,
  labelfontSize = 14,
  fontColor = "white",
  borderColor = "white",
  backgroundColor = "#00000077",
  length,
  validation,
  mask,
}: Props) {
  const [showPass, setShowPass] = useState<boolean>(false);
  const [error, setError] = useState<string | null>(null);
  const inputfield = useRef<HTMLInputElement | null>(null);

  const handleCpfCnpjChange = (event: any) => {
    if (length) {
      if (event.target.value.length > length) {
        event.target.value = event.target.value.substr(0, length);
      }
    }

    if (type === "cpfcnpj") {
      // Get only the numbers from the data input
      let data = event.target.value.replace(/\D/g, "");
      // Checking data length to define if it is cpf or cnpj
      if (data.length > 11) {
        // It's cnpj
        let cnpj = `${data.substr(0, 2)}.${data.substr(2, 3)}.${data.substr(
          5,
          3
        )}/`;
        if (data.length > 12)
          cnpj += `${data.substr(8, 4)}-${data.substr(12, 2)}`;
        else cnpj += data.substr(8);
        // Pass formatting for the data
        data = cnpj;
      } else {
        // It's cpf
        let cpf = "";
        let parts = Math.ceil(data.length / 3);
        for (let i = 0; i < parts; i++) {
          if (i === 3) {
            cpf += `-${data.substr(i * 3)}`;
            break;
          }
          cpf += `${i !== 0 ? "." : ""}${data.substr(i * 3, 3)}`;
        }
        // Pass formatting for the data
        data = cpf;
      }
      if (inputfield.current) {
        inputfield.current.value = data;
      }
    } else if (type === "cpf") {
      // Get only the numbers from the data input
      let data = event.target.value.replace(/\D/g, "");

      if (data.length > 11) {
        data = data.substr(0, 11);
      }

      let cpf = "";
      let parts = Math.ceil(data.length / 3);
      for (let i = 0; i < parts; i++) {
        if (i === 3) {
          cpf += `-${data.substr(i * 3)}`;
          break;
        }
        cpf += `${i !== 0 ? "." : ""}${data.substr(i * 3, 3)}`;
      }

      if (inputfield.current) {
        inputfield.current.value = cpf;
      }
    } else if (type === "card") {
      // Get only the numbers from the data input
      let data = event.target.value.replace(/\D/g, "");

      if (data.length > 16) {
        data = data.substr(0, 16);
      }

      let card = "";
      let parts = Math.ceil(data.length / 4);
      for (let i = 0; i < parts; i++) {
        card += `${i !== 0 ? "." : ""}${data.substr(i * 4, 4)}`;
      }

      if (inputfield.current) {
        inputfield.current.value = card;
      }
    }

    if (mask && inputfield.current) {
      const newValue = mask(event.target.value);
      inputfield.current.value = newValue;
    }
  };
  return (
    <div
      style={{
        display: "flex",
        textAlign: "left",
        fontSize: fontSize + "px",
        paddingBottom: "20px",
        justifyContent: "space-between",
      }}
    >
      <div></div>
      <div
        style={{
          textAlign: "left",
          width: width,
        }}
      >
        <div
          style={{
            fontSize: labelfontSize + "px",
            paddingBottom: "3px",
          }}
        >
          {label}
        </div>
        <div
          style={{
            borderStyle: "solid",
            borderRadius: "25px",
            paddingTop: "10px",
            paddingBottom: "10px",
            display: "flex",
            borderWidth: "1px",
            backgroundColor: backgroundColor,
            borderColor: borderColor,
          }}
        >
          <input
            className="whinput"
            id={id}
            type={type === "password" ? (showPass ? "text" : "password") : type}
            style={{
              background: "transparent",
              backgroundColor: "transparent",
              borderStyle: "none",
              width: "100%",
              paddingLeft: "20px",
              paddingRight: "20px",
              outline: "none",
              outlineColor: "transparent",
              accentColor: "transparent",
              color: fontColor,
              lightingColor: "transparent",
              fontSize: fontSize,
            }}
            defaultValue={defaultValue}
            onChange={handleCpfCnpjChange}
            onBlur={(event) => {
              if (validation) {
                const hasError = validation(event?.target?.value);

                if (hasError === "" || hasError === null) {
                  setError(null);
                } else {
                  setError(hasError);
                }
              }
            }}
            ref={inputfield}
          />
          {type === "password" ? (
            <button
              style={{
                backgroundColor: "transparent",
                borderStyle: "none",
              }}
              onClick={() => {
                setShowPass(!showPass);
              }}
              type="button"
            >
              <img
                src={showPass ? "eye.png" : "hide.png"}
                width="15px"
                style={{ paddingRight: "10px" }}
              />
            </button>
          ) : (
            <div />
          )}
        </div>
        {subtext === undefined ? (
          <div />
        ) : (
          <div
            style={{
              fontSize: subtextsize === undefined ? "8px" : subtextsize,
              paddingTop: "3px",
            }}
          >
            {subtext}
          </div>
        )}

        {error && (
          <div
            style={{
              fontSize: "12px",
              paddingTop: "3px",
              color: "red",
              maxWidth: "280px",
              overflow: "auto",
            }}
          >
            {error}
          </div>
        )}
      </div>
      <div></div>
    </div>
  );
}
