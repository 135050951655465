import { Dispatch, SetStateAction, useRef, useState } from "react";
import { format } from "../../../utils/Utils";
import "./RoundInput.css";

export interface Props {
  label: string;
  type: string;
  id: string;
  labelFontSize?: number;
  subtext?: string;
  subtextsize?: string;
  defaultValue?: number | string;
  editable?: boolean;
  width?: number;
  length?: number;
  textArea?: boolean;
  bold?: boolean;
  borderColor?: string;
  setNumber: Dispatch<SetStateAction<number>>;
}

export function InputValue({
  label,
  type,
  id,
  labelFontSize = 14,
  subtext,
  subtextsize,
  defaultValue,
  editable = true,
  width = 550,
  length,
  textArea = false,
  bold = false,
  borderColor = "#cccccc",
  setNumber,
}: Props) {
  const [showPass, setShowPass] = useState<boolean>(false);
  const inputfield = useRef<HTMLInputElement | null>(null);

  const handleCpfCnpjChange = (event: any) => {
    if (length) {
      if (event.target.value.length > length) {
        event.target.value = event.target.value.substr(0, length);
      }
    }

    if (type === "money") {
      // Get only the numbers from the data input
      let data = event.target.value.replace(/\D/g, "");
      setNumber(Number.parseInt(data) / 100);
      if (inputfield.current) {
        inputfield.current.value =
          "R$ " + format(Number.parseInt(data) / 100, "Decimal");
      }
    }
  };

  return (
    <div
      style={{
        display: "flex",
        textAlign: "left",
        fontSize: "14px",
        paddingBottom: "20px",
        justifyContent: "space-between",
      }}
    >
      <div></div>
      <div
        style={{
          textAlign: "left",
          fontSize: labelFontSize,
        }}
      >
        <div style={{ fontWeight: bold ? "bold" : "normal" }}>{label}</div>
        <div
          style={{
            borderStyle: "solid",
            paddingTop: "10px",
            paddingBottom: "10px",
            width: width + "px",
            display: "flex",
            borderWidth: "1px",
            borderRadius: "15px",
            backgroundColor: "#cccccc",
            borderColor: borderColor,
          }}
        >
          {textArea ? (
            <textarea
              id={id}
              style={{
                background: "transparent",
                backgroundColor: "transparent",
                borderStyle: "none",
                width: "100%",
                paddingLeft: "20px",
                outline: "none",
                outlineColor: "transparent",
                accentColor: "transparent",
                color: "black",
                lightingColor: "transparent",
                resize: "none",
              }}
              rows={7}
              placeholder="Escreva sua mensagem aqui"
            >
              {defaultValue}
            </textarea>
          ) : editable ? (
            <input
              id={id}
              type={
                type === "password" ? (showPass ? "text" : "password") : type
              }
              style={{
                background: "transparent",
                backgroundColor: "transparent",
                borderStyle: "none",
                width: "100%",
                paddingLeft: "20px",
                outline: "none",
                outlineColor: "transparent",
                accentColor: "transparent",
                color: "black",
                lightingColor: "transparent",
              }}
              defaultValue={defaultValue}
              onChange={handleCpfCnpjChange}
              ref={inputfield}
            />
          ) : (
            <input
              id={id}
              type={
                type === "password" ? (showPass ? "text" : "password") : type
              }
              style={{
                background: "transparent",
                backgroundColor: "transparent",
                borderStyle: "none",
                width: "100%",
                paddingLeft: "20px",
                outline: "none",
                outlineColor: "transparent",
                accentColor: "transparent",
                color: "black",
                lightingColor: "transparent",
              }}
              value={defaultValue}
              readOnly={true}
            />
          )}
          {type === "password" ? (
            <button
              style={{
                backgroundColor: "transparent",
                borderStyle: "none",
              }}
              onClick={() => {
                setShowPass(!showPass);
              }}
              type="button"
            >
              <img
                src={showPass ? "eye.png" : "hide.png"}
                width="15px"
                style={{ paddingRight: "10px" }}
              />
            </button>
          ) : (
            <div />
          )}
        </div>
        {subtext === undefined ? (
          <div />
        ) : (
          <div
            style={{
              fontSize: subtextsize === undefined ? "8px" : subtextsize,
              paddingTop: "3px",
            }}
          >
            {subtext}
          </div>
        )}
      </div>
      <div></div>
    </div>
  );
}
