import { useEffect, useState } from "react";
import {
  CampaignType,
  getAllRaffles,
  getAllRafflesByType,
} from "../../../services/login";
import { CardProps } from "../Components/Card";
import { ResultsGrid } from "../Components/ResultsGrid";
import { ShowcaseItem } from "../Components/ShowcaseItem";
import { AllRafflesResult, FillRaffleImages } from "../PaginaInicio";
import { useAuth } from "../../../contexts/AuthContext";
import { ShowcaseItem_M } from "../Components/ShowcaseItem_M";
import { ResultsGrid_M } from "../Components/ResultsGrid_M";

export interface PaginaSorteioProps {}

export function PaginaSorteio_M({}: PaginaSorteioProps) {
  const [sorteios, setSorteios] = useState<CardProps[]>([]);
  const [featured, setFeatured] = useState<CardProps[]>([]);
  const [sorteiosFiltrados, setSorteiosFiltrados] = useState<CardProps[]>([]);
  const [selected, setSelected] = useState<number>();
  const [btSelected, setBtSelected] = useState<number>(0);
  const { hasPermission } = useAuth();

  useEffect(() => {
    getAllRaffles((rest: AllRafflesResult) => {
      const data: CardProps[] = [];
      for (let index = 0; index < rest.content.length; index++) {
        const element = rest.content[index];
        FillRaffleImages(element, () => {
          data.push({
            raffle: element,
          });
          setSorteios([...data]);
          setSorteiosFiltrados([...data]);
        });
      }
    });
  }, []);

  const [dataCarrossel, setDataCarrossel] = useState<CardProps[]>([]);
  useEffect(() => {
    getAllRafflesByType(CampaignType.FEATURED, (rest: AllRafflesResult) => {
      const data: CardProps[] = [];
      for (let index = 0; index < rest.content.length; index++) {
        const element = rest.content[index];
        FillRaffleImages(element, () => {
          data.push({
            raffle: element,
          });
          setFeatured([...data]);
        });
      }
    });
  }, []);

  /*
  if (sorteios.length === 0) {
    for (let index = 0; index < 15; index++) {
      sorteios.push({
        title: "titulo",
        icon: "clock.png",
        line: "blablabla",
        cardheadimage: "green_background2.png",
        description: "GOLDEN NUMBER",
        prize: "Apartamento",
        ranking: "1º lugar",
        gray: index % 3 === 2,
        idPrize: 4,
      });
    }
    setSorteiosFiltrados(sorteios);
  }
  */
  return (
    <div
      style={{
        color: "white",
        paddingTop: "30px",
        maxWidth: "100%",
        textAlign: "center",
      }}
    >
      <div
        style={{
          position: "absolute",
          top: "0px",
          left: "0px",
          width: "100%",
          height: "300px",
          minHeight: "300px",
          overflow: "hidden",
        }}
      >
        <div
          style={{
            position: "absolute",
            right: "100px",
            top: "150px",
            fontSize: "36px",
            fontWeight: "bold",
            textAlign: "right",
            zIndex:"100"
          }}
        >
          SEJA O<br />
          PRÓXIMO
          <br />
          NUMBER WIN!
          <br />
          {/*
            <button
              style={{
                borderRadius: "20px",
                borderColor: "transparent",
                backgroundColor: "#22AA22",
                alignSelf: "center",
                width: "80%",
                marginTop: "10px",
                paddingRight: "20px",
                fontSize: "14px",
                textAlign: "center",
                fontWeight: "bold",
                color: "white",
                whiteSpace: "nowrap",
                padding: "10px",
              }}
              onClick={() => {
                if (hasPermission("ROLE_CLIENT")) {
                  window.location.href =
                    window.location.href.substring(
                      0,
                      window.location.href.indexOf("?")
                    ) +
                    "?pag=8&raffleId=" +
                    raffle.id;
                } else {
                  Messager.message(
                    "Ops...",
                    "É necessário estar logado para comprar cupons."
                  );
                }
              }}
            >
              Comprar cupons
            </button>*/}
        </div>

        <div
          style={{
            position: "absolute",
            left: "-150px",
            top: "0px",
          }}
        >
          <div style={{}}>
            <img src="coins.png" width={"400px"}/>
          </div>
        </div>
        <div
          style={{
            position: "absolute",
            bottom: "0px",
            left: "0px",
            width: "100%",
            display: "flex",
            pointerEvents: "none",
          }}
        >
          <img
            src="gradient.png"
            style={{
              width: "100%",
              objectFit: "cover",
              overflow: "hidden",
            }}
          />
        </div>
        <img src="Sorteios.png" style={{ width: "100%", minHeight: "500px" }} />
      </div>
      <div
        style={{
          paddingTop: "300px",
          backgroundImage:
            "url(" +
            require("../../../assets/images/green_background.png") +
            ")",
          backgroundSize: "cover",
        }}
      >
        <div
          style={{
            fontSize: "30px",
          }}
        >
          <div
            style={{
              display: "flex",
              paddingLeft: "30px",
              width: "calc(90% - 100px)",
              justifyContent: "space-evenly",
              paddingBottom: "60px",
            }}
          >
            {featured.map((f, idx) => {
              if(idx>2){
                return null;
              }
              return (
                <div key={"showcase" + idx}>
                  <ShowcaseItem_M
                    top={f.raffle.name}
                    desc={f.raffle.prizes[0].name + ""}
                    image={f.raffle.imageFiles[0]}
                    raffleId={f.raffle.id}
                    selected={selected}
                    setSelected={setSelected}
                    index={idx}
                    isAlive={f.raffle.isActive}
                  />
                </div>
              );
            })}
          </div>
        </div>
        <ResultsGrid_M
          data={sorteiosFiltrados}
          buttons={[
            {
              label: "Todos Sorteios",
              onClick: () => {
                setBtSelected(0);
                setSorteiosFiltrados([...sorteios]);
              },
              isSelected: () => {
                return btSelected === 0;
              },
            },
            {
              label: "Sorteios Ativos",
              onClick: () => {
                setBtSelected(1);
                const sorteiosF = [];
                for (let index = 0; index < sorteios.length; index++) {
                  const element = sorteios[index];
                  if (element.raffle.isActive) {
                    sorteiosF.push(element);
                  }
                }
                setSorteiosFiltrados(sorteiosF);
              },
              isSelected: () => {
                return btSelected === 1;
              },
            },
            {
              label: "Sorteios Encerrados",
              onClick: () => {
                setBtSelected(2);
                const sorteiosF = [];
                for (let index = 0; index < sorteios.length; index++) {
                  const element = sorteios[index];
                  if (!element.raffle.isActive) {
                    sorteiosF.push(element);
                  }
                }
                setSorteiosFiltrados(sorteiosF);
              },
              isSelected: () => {
                return btSelected === 2;
              },
            },
          ]}
        />
      </div>
    </div>
  );
}
