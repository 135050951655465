import { profile } from "console";
import { useEffect, useState } from "react";
import {
  getDocStatus,
  getProfile,
  sendDoc,
  setProfile,
} from "../../../services/login";
import { RectInput } from "../Components/RectInput";
import { RectInputUpload } from "../Components/RectInputUpload";
import { RectSelect } from "../Components/RectSelect";
import { Messager } from "../MainPage";

export interface Props {
  fontSize?:number
}
export interface PerfilProps {
  name: string;
  lastName: string;
  email: string;
  location: string;
  bio: string;
  username: string;
}

export function ConfigPerfil({fontSize = 16}: Props) {
  const [perfil, setPerfil] = useState<PerfilProps>();
  const [selfie, setSelfie] = useState<string>("checking...");
  const [doc, setDoc] = useState<string>("checking...");
  const [address, setAddress] = useState<string>("checking...");
  useEffect(() => {
    if (perfil === undefined) {
      getProfile((data: any) => {
        setPerfil(data.response);
      });
    }
    if (selfie === "checking...") {
      getDocStatus((resp: any) => {
        setAddress(resp.response.docs.ADDRESS);
        setDoc(resp.response.docs.DOC);
        setSelfie(resp.response.docs.SHELFIE);
      });
    }
  }, []);

  function statusToInputType(status: string) {
    if (status === "checking...") {
      return "text";
    }
    if (status === "NONE") {
      return "file";
    }
    if (status === "REJECTED") {
      return "file";
    }
    if (status === "APPROVED") {
      return "text";
    }
    if (status === "PENDING") {
      return "text";
    }
    return "text";
  }

  function statusToMessage(status: string) {
    if (status === "checking...") {
      return "Verificando status...";
    }
    if (status === "NONE") {
      return " Documento Pendente. Envie-o aqui.";
    }
    if (status === "REJECTED") {
      return " Documento Rejeitado. Verifique e envie-o novamente.";
    }
    if (status === "APPROVED") {
      return " Documento Enviado e Aprovado Com Sucesso!";
    }
    if (status === "PENDING") {
      return " Documento Enviado Com Sucesso! Análise em andamento.";
    }
    return "Erro. Status desconhecido.";
  }

  return (
    <div>
      <div
        style={{
          backgroundColor: "white",
          borderRadius: "10px",
          padding: "20px",
          borderColor: "white",
          borderStyle: "solid",
          borderWidth: "3px",
          width: "100%",
        }}
      >
        <div style={{ fontSize: fontSize+"px", display: "flex" }}>
          <div>
            <b>Informações de Perfil</b>
          </div>
          <img
            src="icon_info.png"
            style={{ paddingLeft: "5px", paddingTop: "5px" }}
          />
        </div>
        <hr color="gray" />
        <div style={{ width: "calc(100% - 50px)" }}>
          <RectInput
            label={"Nome"}
            type={"text"}
            id={"userName"}
            defaultValue={perfil?.name}
            width={"100%"}
            fontSize={window.innerWidth<1000?12:14}       
          />
          <RectInput
            label={"Sobrenome"}
            type={"text"}
            id={"userLastName"}
            defaultValue={perfil?.lastName}
            width={"100%"}
            fontSize={window.innerWidth<1000?12:14}  
          />
          <RectInput
            label={"Apelido"}
            type={"text"}
            id={"userLogin"}
            defaultValue={perfil?.username}
            width={"100%"}
            fontSize={window.innerWidth<1000?12:14}  
          />
          <RectInput
            label={"Email"}
            type={"text"}
            id={"userEmail"}
            defaultValue={perfil?.email}
            editable={false}
            width={"100%"}
            fontSize={window.innerWidth<1000?12:14}  
          />
          {/*
          <div
            style={{
              display: "flex",
              justifyContent: "space-between",
              paddingLeft: "25px",
              paddingRight: "25px",
            }}
          >
            <RectSelect
              label={"País"}
              options={["Brazil", "E.U.A.", "México", "Argentina"]}
              values={["br", "us", "mx", "ar"]}
              id={"userCountry"}
              width={150}
            />
            <RectSelect
              label={"Cidade"}
              options={[
                "Florianopolis",
                "Los Angeles",
                "Los Muertos",
                "Buenos Aires",
              ]}
              values={["br", "us", "mx", "ar"]}
              id={"userCountry"}
              width={150}
            />
            <RectInput
              label={"CEP"}
              type={"text"}
              id={"userEmail"}
              defaultValue="880XX-XXX"
              width={150}
            />
          </div>
            */}
          <RectInput
            label={"Endereço"}
            type={"text"}
            id={"userLocation"}
            defaultValue={perfil?.location}
            width={"100%"}
            fontSize={window.innerWidth<1000?12:14}  
          />
          <RectInput
            label={"Bio"}
            type={"text"}
            id={"userBio"}
            defaultValue={perfil?.bio}
            width={"100%"}
            fontSize={window.innerWidth<1000?12:14}  
          />
          <div style={{ display: "flex", justifyContent: "space-between" }}>
            <div></div>
            <button
              style={{
                padding: "10px",
                backgroundColor: "green",
                color: "white",
                borderRadius: "5px",
                borderColor: "transparent",
                borderWidth: "1px",
                marginRight: "20px",
                width: "80px",
              }}
              onClick={() => {
                const userName = (
                  document.getElementById("userName") as HTMLInputElement
                ).value as string;
                const userLastName = (
                  document.getElementById("userLastName") as HTMLInputElement
                ).value as string;
                const userLogin = (
                  document.getElementById("userLogin") as HTMLInputElement
                ).value as string;
                //username
                const userEmail = (
                  document.getElementById("userEmail") as HTMLInputElement
                ).value as string;
                const userLocation = (
                  document.getElementById("userLocation") as HTMLInputElement
                ).value as string;
                const userBio = (
                  document.getElementById("userBio") as HTMLInputElement
                ).value as string;
                const props = {
                  name: userName,
                  lastName: userLastName,
                  username: userLogin,
                  email: perfil?.email,
                  bio: userBio,
                  location: userLocation,
                };
                setProfile(props, (answer: any) => {
                  if (answer.message === "Cliente atualizado com sucesso.") {
                    Messager.setTitle("Sucesso!");
                    const msg = "Perfil atualizado com sucesso!";
                    Messager.setMessage(
                      msg +
                        (perfil?.username != userLogin
                          ? "\r\n Faça login novamente para atualizar o seu apelido."
                          : "")
                    );
                    Messager.setConfirm("OK");
                    Messager.setInvertColors(false);
                  }
                });
              }}
            >
              Save
            </button>
          </div>
        </div>
      </div>
      <div
        style={{
          backgroundColor: "#EEEEEE",
          borderRadius: "10px",
          padding: "20px",
          borderColor: "#EEEEEE",
          borderStyle: "solid",
          borderWidth: "3px",
          marginTop: "30px",
          width: "calc(100% - 50px)",
        }}
      >
        <div style={{ fontSize: "20px" }}>
          <b>Meus Documentos</b>
        </div>
        <div
          style={{
            backgroundColor: "white",
            borderRadius: "10px",
            padding: "20px",
            borderColor: "white",
            borderStyle: "solid",
            borderWidth: "3px",
            marginTop: "10px",
          }}
        >
          <div>
            <RectInputUpload
              label={"Documento Nacional Com Foto"}
              type={statusToInputType(doc)}
              id={"userDocID"}
              editable={false}
              subtext={"Necessário Validação Para Saque"}
              labelBtn={statusToMessage(doc)}
              onChange={function (val): void {
                var file = val?.target?.files[0];
                sendDoc(file, "DOC", (ans: any) => {
                  if (ans.message === "Documento enviado com sucesso") {
                    setDoc("PENDING");
                  }
                });
              }}
              width={"100%"}
              fontSize={window.innerWidth<1000?12:14}  
            />
            <RectInputUpload
              label={"Selfie com Documento"}
              type={statusToInputType(selfie)}
              id={"userDocSelfie"}
              editable={false}
              subtext={"Necessário Validação Para Saque"}
              labelBtn={statusToMessage(selfie)}
              onChange={function (val): void {
                var file = val?.target?.files[0];
                sendDoc(file, "SHELFIE", (ans: any) => {
                  if (ans.message === "Documento enviado com sucesso") {
                    setSelfie("PENDING");
                  }
                });
              }}
              width={"100%"}
              fontSize={window.innerWidth<1000?12:14}  
            />
            <RectInputUpload
              label={"Comprovante Oficial De Residência"}
              type={statusToInputType(address)}
              id={"userDocRes"}
              subtext={"Necessário Validação Para Saque"}
              labelBtn={statusToMessage(address)}
              onChange={function (val): void {
                var file = val?.target?.files[0];
                sendDoc(file, "ADDRESS", (ans: any) => {
                  if (ans.message === "Documento enviado com sucesso") {
                    setAddress("PENDING");
                  }
                });
              }}
              width={"100%"}
              fontSize={window.innerWidth<1000?12:14}  
            />
          </div>
        </div>
      </div>
    </div>
  );
}
