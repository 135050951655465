import sample from "../../../assets/videos/numberwinv2.mp4";

import "../PaginaInicio.css";
import "../specialeffects.css";

export interface RegisterData {
  nome: string;
  sobrenome: string;
  senha: string;
  email: string;
  telefone: string;
  cpf: string;
  dia: string;
  mes: string;
  ano: string;
}

export function PaginaFaq() {
  return (
    <div style={{ backgroundColor: "white" }}>
      <div style={{ position: "relative" }}>
        <div style={{ height: "600px", overflow: "hidden" }}>
          <video
            className="videoTag"
            style={{ height: "100%" }}
            autoPlay
            loop
            muted
          >
            <source src={sample} type="video/mp4" />
          </video>
        </div>
        <div
          style={{
            position: "absolute",
            bottom: "0px",
            left: "0px",
            width: "100%",
            height: "100%",
            backgroundColor: "#00000022",
          }}
        ></div>
      </div>
      <div style={{ position: "relative" }}>
        <div
          style={{
            position: "absolute",
            top: "-180px",
            left: "0px",
            width: "100%",
            height: "100%",
          }}
        >
          <div
            style={{
              fontSize: 50,
              color: "white",
              paddingTop: "0px",
              paddingLeft: "12%",
              fontWeight: "bold",
            }}
          >
            Perguntas
          </div>
          <div
            style={{
              fontSize: 50,
              color: "white",
              paddingTop: "10px",
              paddingLeft: "12%",
              fontWeight: "bold",
              paddingBottom: "20px",
            }}
          >
            Frequentes
          </div>
        </div>
        <div
          style={{
            backgroundColor: "white",
            color: "black",
          }}
        >
          <div
            style={{
              fontWeight: "bold",
              paddingLeft: "12%",
              fontSize: "30px",
            }}
          >
            1. Pergunta 1:
          </div>
          <div style={{ display: "flex" }}>
            <div
              style={{
                paddingLeft: "12%",
                fontSize: "16px",
                minWidth: "60%",
                maxWidth: "60%",
                paddingRight: "30px",
              }}
            >
              <br />
              "Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do
              eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut
              enim ad minim veniam, quis nostrud exercitation ullamco laboris
              nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in
              reprehenderit in voluptate velit esse cillum dolore eu fugiat
              nulla pariatur. Excepteur sint occaecat cupidatat non proident,
              sunt in culpa qui officia deserunt.
              <br />
              <br />
              "Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do
              eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut
              enim ad minim veniam, quis nostrud exercitation ullamco laboris
              nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in
              reprehenderit in voluptate velit esse cillum dolore eu fugiat
              nulla pariatur. Excepteur sint occaecat cupidatat non proident,
              sunt in culpa qui officia deserunt.
              <br />
            </div>
          </div>
          <hr />
          <br />
          <br />
          <div
            style={{
              fontWeight: "bold",
              paddingLeft: "12%",
              fontSize: "30px",
            }}
          >
            2. Pergunta 2:
          </div>
          <div style={{ display: "flex" }}>
            <div
              style={{
                paddingLeft: "12%",
                fontSize: "16px",
                minWidth: "60%",
                maxWidth: "60%",
                paddingRight: "30px",
              }}
            >
              <br />
              "Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do
              eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut
              enim ad minim veniam, quis nostrud exercitation ullamco laboris
              nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in
              reprehenderit in voluptate velit esse cillum dolore eu fugiat
              nulla pariatur. Excepteur sint occaecat cupidatat non proident,
              sunt in culpa qui officia deserunt.
              <br />
              <br />
              "Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do
              eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut
              enim ad minim veniam, quis nostrud exercitation ullamco laboris
              nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in
              reprehenderit in voluptate velit esse cillum dolore eu fugiat
              nulla pariatur. Excepteur sint occaecat cupidatat non proident,
              sunt in culpa qui officia deserunt.
              <br />
            </div>
          </div>
          <hr />
          <br />
          <br />
          <div
            style={{
              fontWeight: "bold",
              paddingLeft: "12%",
              fontSize: "30px",
            }}
          >
            3. Pergunta 3:
          </div>
          <div style={{ display: "flex" }}>
            <div
              style={{
                paddingLeft: "12%",
                fontSize: "16px",
                minWidth: "60%",
                maxWidth: "60%",
                paddingRight: "30px",
              }}
            >
              <br />
              "Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do
              eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut
              enim ad minim veniam, quis nostrud exercitation ullamco laboris
              nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in
              reprehenderit in voluptate velit esse cillum dolore eu fugiat
              nulla pariatur. Excepteur sint occaecat cupidatat non proident,
              sunt in culpa qui officia deserunt.
              <br />
              <br />
              "Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do
              eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut
              enim ad minim veniam, quis nostrud exercitation ullamco laboris
              nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in
              reprehenderit in voluptate velit esse cillum dolore eu fugiat
              nulla pariatur. Excepteur sint occaecat cupidatat non proident,
              sunt in culpa qui officia deserunt.
              <br />
            </div>
          </div>
          <hr />
          <br />
          <br />
          <div
            style={{
              fontWeight: "bold",
              paddingLeft: "12%",
              fontSize: "30px",
            }}
          >
            4. Pergunta 4:
          </div>
          <div style={{ display: "flex" }}>
            <div
              style={{
                paddingLeft: "12%",
                fontSize: "16px",
                minWidth: "60%",
                maxWidth: "60%",
                paddingRight: "30px",
              }}
            >
              <br />
              "Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do
              eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut
              enim ad minim veniam, quis nostrud exercitation ullamco laboris
              nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in
              reprehenderit in voluptate velit esse cillum dolore eu fugiat
              nulla pariatur. Excepteur sint occaecat cupidatat non proident,
              sunt in culpa qui officia deserunt.
              <br />
              <br />
              "Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do
              eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut
              enim ad minim veniam, quis nostrud exercitation ullamco laboris
              nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in
              reprehenderit in voluptate velit esse cillum dolore eu fugiat
              nulla pariatur. Excepteur sint occaecat cupidatat non proident,
              sunt in culpa qui officia deserunt.
              <br />
            </div>
          </div>
          <hr />
          <br />
          <br />
          <div
            style={{
              fontWeight: "bold",
              paddingLeft: "12%",
              fontSize: "30px",
            }}
          >
            5. Pergunta 5:
          </div>
          <div style={{ display: "flex" }}>
            <div
              style={{
                paddingLeft: "12%",
                fontSize: "16px",
                minWidth: "60%",
                maxWidth: "60%",
                paddingRight: "30px",
              }}
            >
              <br />
              "Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do
              eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut
              enim ad minim veniam, quis nostrud exercitation ullamco laboris
              nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in
              reprehenderit in voluptate velit esse cillum dolore eu fugiat
              nulla pariatur. Excepteur sint occaecat cupidatat non proident,
              sunt in culpa qui officia deserunt.
              <br />
              <br />
              "Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do
              eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut
              enim ad minim veniam, quis nostrud exercitation ullamco laboris
              nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in
              reprehenderit in voluptate velit esse cillum dolore eu fugiat
              nulla pariatur. Excepteur sint occaecat cupidatat non proident,
              sunt in culpa qui officia deserunt.
              <br />
            </div>
          </div>
          <hr />
          <br />
          <br />
          <div
            style={{
              fontWeight: "bold",
              paddingLeft: "12%",
              fontSize: "30px",
            }}
          >
            6. Pergunta 6:
          </div>
          <div style={{ display: "flex" }}>
            <div
              style={{
                paddingLeft: "12%",
                fontSize: "16px",
                minWidth: "60%",
                maxWidth: "60%",
                paddingRight: "30px",
              }}
            >
              <br />
              "Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do
              eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut
              enim ad minim veniam, quis nostrud exercitation ullamco laboris
              nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in
              reprehenderit in voluptate velit esse cillum dolore eu fugiat
              nulla pariatur. Excepteur sint occaecat cupidatat non proident,
              sunt in culpa qui officia deserunt.
              <br />
              <br />
              "Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do
              eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut
              enim ad minim veniam, quis nostrud exercitation ullamco laboris
              nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in
              reprehenderit in voluptate velit esse cillum dolore eu fugiat
              nulla pariatur. Excepteur sint occaecat cupidatat non proident,
              sunt in culpa qui officia deserunt.
              <br />
            </div>
          </div>
          <hr />
          <br />
          <br />
          <div
            style={{
              fontWeight: "bold",
              paddingLeft: "12%",
              fontSize: "30px",
            }}
          >
            7. Pergunta 7:
          </div>
          <div style={{ display: "flex" }}>
            <div
              style={{
                paddingLeft: "12%",
                fontSize: "16px",
                minWidth: "60%",
                maxWidth: "60%",
                paddingRight: "30px",
              }}
            >
              <br />
              "Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do
              eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut
              enim ad minim veniam, quis nostrud exercitation ullamco laboris
              nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in
              reprehenderit in voluptate velit esse cillum dolore eu fugiat
              nulla pariatur. Excepteur sint occaecat cupidatat non proident,
              sunt in culpa qui officia deserunt.
              <br />
              <br />
              "Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do
              eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut
              enim ad minim veniam, quis nostrud exercitation ullamco laboris
              nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in
              reprehenderit in voluptate velit esse cillum dolore eu fugiat
              nulla pariatur. Excepteur sint occaecat cupidatat non proident,
              sunt in culpa qui officia deserunt.
              <br />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
