import React, { useEffect, useState } from "react";
import { motion, useMotionValue } from "framer-motion";
import { Card, CardProps } from "./Card";
import { Card_M } from "./Card_M";

const DRAG_BUFFER = 50;

const SPRING_OPTIONS = {
  type: "spring",
  mass: 3,
  stiffness: 400,
  damping: 50,
};

interface Prop {
  dados: CardProps[];
}

export const SwipeCarousel_M = ({ dados }: Prop) => {
  const [imgIndex, setImgIndex] = useState(0);

  useEffect(() => {
    if (imgIndex < 0) {
      setImgIndex(imgIndex + dados.length);
    }
    if (imgIndex >= dados.length) {
      setImgIndex(imgIndex - dados.length);
    }
  }, [imgIndex]);

  return (
    <div>
      <div
        className="relative overflow-hidden bg-neutral-950 py-8"
        style={{ overflow: "hidden" }}
      >
        <motion.div
          style={{
            display: "flex",
          }}
          animate={{
            translateX:
              `${(imgIndex) * -352}px`,
          }}
          transition={SPRING_OPTIONS}
          className="flex cursor-grab items-center active:cursor-grabbing"
        >
          {dados.map((d, idx) => {
            return (
              <motion.div
                key={idx}
                animate={{
                  scale: imgIndex === idx ? 0.95 : 0.75,
                }}
                transition={SPRING_OPTIONS}
                className="aspect-video shrink-0 rounded-xl bg-neutral-800 object-cover"
              >
                <Card_M raffle={d.raffle} />
                {imgIndex === idx ? (
                  <div style={{ position: "relative" }}>
                    {imgIndex > 0 ? (
                      <button
                        style={{
                          position: "absolute",
                          top: "-250px",
                          left: "-10px",
                          height: "50px",
                          width: "50px",
                          borderRadius: "25px",
                          backgroundColor: "#456542CC",
                          borderStyle: "solid",
                          borderColor: "#456542CC",
                          color: "white",
                          fontSize: "30px",
                        }}
                        onClick={() => {
                          setImgIndex((pv) => pv - 1);
                        }}
                      >
                        {"<"}
                      </button>
                    ) : (
                      <div></div>
                    )}
                    {imgIndex < dados.length - 1 ? (
                      <button
                        style={{
                          position: "absolute",
                          top: "-250px",
                          left: "340px",
                          height: "50px",
                          width: "50px",
                          borderRadius: "25px",
                          backgroundColor: "#456542CC",
                          borderStyle: "solid",
                          borderColor: "#456542CC",
                          color: "white",
                          fontSize: "30px",
                        }}
                        onClick={() => {
                          setImgIndex((pv) => pv + 1);
                        }}
                      >
                        {">"}
                      </button>
                    ) : (
                      <div></div>
                    )}
                  </div>
                ) : (
                  <div></div>
                )}
              </motion.div>
            );
          })}
        </motion.div>
      </div>
    </div>
  );
};

interface PropImg {
  imgIndex: number;
  dados: CardProps[];
}
