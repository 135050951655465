import sample from "../../../assets/videos/numberwinv4.mp4";

import "../PaginaInicio.css";
import "../specialeffects.css";

import { RectInput } from "../Components/RectInput";
import { MenuButton } from "../Components/MenuButton";

export interface RegisterData {
  nome: string;
  sobrenome: string;
  senha: string;
  email: string;
  telefone: string;
  cpf: string;
  dia: string;
  mes: string;
  ano: string;
}

export function PaginaContato() {
  return (
    <div style={{ backgroundColor: "white" }}>
      <div style={{ position: "relative" }}>
        <div style={{ height: "600px", overflow: "hidden" }}>
          <video
            className="videoTag"
            style={{ height: "100%" }}
            autoPlay
            loop
            muted
          >
            <source src={sample} type="video/mp4" />
          </video>
        </div>
        <div
          style={{
            position: "absolute",
            bottom: "0px",
            left: "0px",
            width: "100%",
            height: "100%",
            backgroundColor: "#00000022",
          }}
        ></div>
      </div>
      <div style={{ position: "relative" }}>
        <div
          style={{
            position: "absolute",
            top: "-180px",
            left: "0px",
            width: "100%",
            height: "100%",
          }}
        >
          <div
            style={{
              fontSize: 50,
              color: "white",
              paddingTop: "80px",
              paddingLeft: "12%",
              fontWeight: "bold",
              paddingBottom: "20px",
            }}
          >
            Reporte um Problema
          </div>
        </div>
        <div style={{ backgroundColor: "white", color: "black" }}>
          <div
            style={{
              paddingLeft: "12%",
              fontSize: "16px",
              width: "60%",
            }}
          >
            <b>The standard Lorem Ipsum passage, used since the 1500s</b>
            <br />
            <br />
            "Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do
            eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim
            ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut
            aliquip ex ea.
          </div>
          <div>
            <br />
            <RectInput label={"Nome"} type={"text"} id={"nome"} />

            <RectInput label={"Email"} type={"email"} id={"email"} />

            <RectInput
              label={"Mensagem"}
              type={"text"}
              id={"mensagem"}
              textArea
            />

            <div
              style={{
                position: "relative",
                paddingBottom: "70px",
              }}
            >
              <div
                style={{
                  width: "200px",
                  position: "absolute",
                  left: "calc(50% - 275px)",
                }}
              >
                <MenuButton label="Enviar Mensagem" action={() => {}} />
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
