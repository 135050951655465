import { useState } from "react";
import "./RoundInput.css";

export interface Props {
  label: string;
  type: string;
  id: string;
  subtext?: string;
  subtextsize?: string;
  editable?: boolean;
  width?: string;
  labelBtn: string;
  borderColor?: string;
  backgroundColor?: string;
  fontSize?:number;
  onChange: (val: any) => void;
}

export function RectInputUpload({
  label,
  type,
  id,
  subtext,
  subtextsize,
  width = "500px",
  borderColor = "#cccccc",
  backgroundColor = "white",
  labelBtn,
  onChange,
  fontSize = 14,
}: Props) {
  const [showPass, setShowPass] = useState<boolean>(false);
  return (
    <div
      style={{
        display: "flex",
        textAlign: "left",
        fontSize: fontSize+"px",
        paddingBottom: "20px",
        justifyContent: "space-between",
      }}
    >
      <div></div>
      <div
        style={{
          textAlign: "left",
          fontSize: fontSize+"px",
          width: width,
        }}
      >
        <div>{label}</div>
        <div
          style={{
            borderStyle: "solid",
            paddingTop: "10px",
            paddingBottom: "10px",
            display: "flex",
            borderWidth: "1px",
            backgroundColor: backgroundColor,
            borderColor: borderColor,
            fontSize: fontSize+"px",
            flexWrap:"wrap",
          }}
        >
          {type === "file" ? (
            <div>
              <div style={{ paddingLeft: "20px" }}>{labelBtn}</div>
              <input
                id={id}
                style={{
                  background: "transparent",
                  backgroundColor: "gray",
                  borderStyle: "none",
                  borderRadius: "5px",
                  paddingLeft: "5px",
                  paddingTop: "5px",
                  outline: "none",
                  outlineColor: "transparent",
                  accentColor: "transparent",
                  color: "white",
                  lightingColor: "transparent",
                  marginLeft: "10px",
                  marginRight: "10px",
                  height: "30px",
                  fontSize: fontSize+"px",
                  width: "90%",
                }}
                type={type}
                onChange={onChange}
              />
            </div>
          ) : (
            <div style={{ paddingLeft: "20px" }}>{labelBtn}</div>
          )}
        </div>
        {subtext === undefined ? (
          <div />
        ) : (
          <div
            style={{
              fontSize: subtextsize === undefined ? "8px" : subtextsize,
              paddingTop: "3px",
            }}
          >
            {subtext}
          </div>
        )}
      </div>
      <div></div>
    </div>
  );
}
