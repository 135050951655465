import "../PaginaInicio.css";
import "../specialeffects.css";

import { useCallback, useEffect, useState } from "react";
import { ConfigPerfil } from "./ConfigPerfil";
import { ConfigNotificacao } from "./ConfigNotificacao";
import { ConfigSenha } from "./ConfigSenha";
import { ConfigPagamento } from "./ConfigPagamento";
import { loadImage } from "./scripts";
import { getProfileImage } from "../../../services/login";
import { UserToken } from "../../../services/UserToken";
import { ModalDeposito } from "./ModalDeposito";

export interface RegisterData {
  nome: string;
  sobrenome: string;
  senha: string;
  email: string;
  telefone: string;
  cpf: string;
  dia: string;
  mes: string;
  ano: string;
}

export function PaginaConfig() {
  const [selectedTab, setSelectedTab] = useState<number>(0);
  const [img, setImg] = useState<any>("");
  const [depositWindow, setDepositWindow] = useState<boolean>(false);
  useEffect(() => {
    getProfileImage((file: any) => {
      if (!file) {
        setImg("");
        return "";
      }
      var reader = new FileReader();
      reader.readAsDataURL(file);

      // here we tell the reader what to do when it's done reading...
      reader.onloadend = (readerEvent) => {
        if (readerEvent) {
          var content = readerEvent.target?.result; // this is the content!
          if (content) {
            setImg(content);
          }
        }
      };
    });
  }, []);
  const pages: JSX.Element[] = [];
  pages.push(<ConfigPerfil />);
  pages.push(<ConfigSenha />);
  //pages.push(<ConfigNotificacao />);
  pages.push(<ConfigPagamento />);
  console.log(img);
  return (
    <div style={{ backgroundColor: "yellow" }}>
      {depositWindow ? <ModalDeposito setModalOpen={setDepositWindow} /> : null}
      <div>
        <div style={{ height: "60px", backgroundColor: "#000000" }}></div>
        <div style={{ backgroundColor: "#fcf7f0" }}>
          <div style={{ display: "flex", width: "100%" }}>
            <div
              style={{
                backgroundColor: "white",
                margin: "30px",
                padding: "20px",
                height: "100%",
                borderRadius: "10px",
                width: "20%",
              }}
            >
              <div style={{ paddingBottom: "15px", fontSize: "18px" }}>
                <b>Configurações</b>
              </div>
              <div>
                <button
                  style={{
                    padding: "10px",
                    backgroundColor:
                      selectedTab === 0 ? "green" : "transparent",
                    color: selectedTab === 0 ? "white" : "gray",
                    borderRadius: "5px",
                    borderColor: "transparent",
                    width: "100%",
                    textAlign: "left",
                  }}
                  onClick={() => {
                    setSelectedTab(0);
                  }}
                >
                  Perfil
                </button>
              </div>
              <div>
                <button
                  style={{
                    padding: "10px",
                    backgroundColor:
                      selectedTab === 1 ? "green" : "transparent",
                    color: selectedTab === 1 ? "white" : "gray",
                    borderRadius: "5px",
                    borderColor: "transparent",
                    width: "100%",
                    textAlign: "left",
                  }}
                  onClick={() => {
                    setSelectedTab(1);
                  }}
                >
                  Senha
                </button>
              </div>
              {/*
              <div>
                <button
                  style={{
                    padding: "10px",
                    backgroundColor:
                      selectedTab === 2 ? "green" : "transparent",
                    color: selectedTab === 2 ? "white" : "gray",
                    borderRadius: "5px",
                    borderColor: "transparent",
                    width: "100%",
                    textAlign: "left",
                  }}
                  onClick={() => {
                    //setSelectedTab(2);
                  }}
                >
                  { Notificação 
                </button>
              </div>*/}
              <div>
                <button
                  style={{
                    padding: "10px",
                    backgroundColor:
                      selectedTab === 2 ? "green" : "transparent",
                    color: selectedTab === 2 ? "white" : "gray",
                    borderRadius: "5px",
                    borderColor: "transparent",
                    width: "100%",
                    textAlign: "left",
                  }}
                  onClick={() => {
                    setSelectedTab(2);
                  }}
                >
                  Pagamento
                </button>
              </div>
              <div>
                <button
                  style={{
                    padding: "10px",
                    backgroundColor:
                      selectedTab === 4 ? "green" : "transparent",
                    color: selectedTab === 4 ? "white" : "gray",
                    borderRadius: "5px",
                    borderColor: "transparent",
                    width: "100%",
                    textAlign: "left",
                  }}
                  onClick={() => {
                    document.dispatchEvent(new CustomEvent("logout"));
                  }}
                >
                  Logout
                </button>
              </div>
            </div>
            <div
              style={{
                position: "relative",
                backgroundColor: "white",
                margin: "30px",
                height: "235px",
                borderRadius: "10px",
                width: "80%",
                overflow: "hidden",
              }}
            >
              <div
                style={{
                  backgroundImage:
                    "url(" +
                    require("../../../assets/images/profilebar.png") +
                    ")",
                  backgroundRepeat: "no-repeat",
                  backgroundSize: "100% 100%",
                  height: "135px",
                }}
              ></div>
              <img
                id="profilePic"
                style={{
                  position: "absolute",
                  top: "100px",
                  left: "20px",
                  backgroundColor: "#419802",
                  borderRadius: "67px",
                  padding: "5px",
                  borderColor: "white",
                  borderStyle: "solid",
                  borderWidth: "3px",
                }}
                src={img !== "" ? img : "iconUser.png"}
                width={"100px"}
                height={"100px"}
                onClick={loadImage}
              />

              <div
                style={{
                  display: "flex",
                  justifyContent: "space-between",
                  marginLeft: "150px",
                  marginTop: "20px",
                }}
              >
                <div>
                  <div>
                    <b>{UserToken.login}</b>
                  </div>
                  <div>{UserToken.email}</div>
                </div>
              </div>
            </div>
          </div>
          <div
            style={{
              display: "flex",
              width: "100%",
            }}
          >
            <div
              style={{
                margin: "30px",
                padding: "20px",
                height: "100%",
                borderRadius: "10px",
                width: "20%",
              }}
            ></div>
            <div
              style={{
                position: "relative",
                margin: "30px",
                borderRadius: "10px",
                width: "80%",
                overflow: "hidden",
              }}
            >
              {pages.map((p, idx) => {
                if (idx === selectedTab) {
                  return <div key={"page" + idx}>{p}</div>;
                } else {
                  return <div key={"page" + idx}></div>;
                }
              })}
            </div>
          </div>
          <div
            style={{
              paddingBottom: "60px",
            }}
          />
        </div>
        <div className="part4">
          <div
            style={{
              position: "relative",
            }}
          >
            <div
              style={{
                position: "absolute",
                top: "-200px",
                left: "calc(60% - 200px)",
                overflow: "hidden",
                width: "calc(40% + 200px)",
                pointerEvents: "none",
              }}
            >
              <img src="laptop.png" />
            </div>
          </div>
          <div
            style={{
              color: "white",
              fontWeight: "bolder",
              fontSize: "36px",
              paddingTop: "50px",
              paddingLeft: "200px",
            }}
          >
            Compre Crédito
          </div>
          <div
            style={{
              paddingTop: "20px",
              color: "white",
              fontSize: "16px",
              paddingLeft: "200px",
              width: "calc(60% - 130px)",
            }}
          >
            Nosso White Paper está disponível para leitura e fornece informações
            detalhadas sobre o nosso projeto, incluindo sua finalidade,
            tecnologia, recursos e como ele planeja alcançar seus objetivos.
          </div>
          <div
            style={{
              display: "flex",
              paddingTop: "40px",
              paddingLeft: "200px",
            }}
          >
            <div></div>
            <button
              style={{
                borderRadius: "20px",
                borderColor: "transparent",
                backgroundColor: "white",
                color: "black",
                fontSize: "18px",
                fontWeight: "bold",
                paddingTop: "10px",
                paddingBottom: "10px",
                paddingLeft: "40px",
                paddingRight: "40px",
              }}
              onClick={() => {
                setDepositWindow(true);
              }}
            >
              Compre Aqui
            </button>
            <div></div>
          </div>
        </div>
      </div>
    </div>
  );
}
