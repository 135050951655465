import React, { useEffect, useState } from "react";
import { motion, useMotionValue } from "framer-motion";
import { Card, CardProps } from "./Card";

const DRAG_BUFFER = 50;

const SPRING_OPTIONS = {
  type: "spring",
  mass: 3,
  stiffness: 400,
  damping: 50,
};

interface Prop {
  imagens: any[];
}

export const ImageCarousel = ({ imagens }: Prop) => {
  const [imgIndex, setImgIndex] = useState(0);
  const [pageIndex, setPageIndex] = useState(0);

  useEffect(() => {
    if (imgIndex < 0) {
      setImgIndex(imgIndex + imagens.length);
    }
    if (imgIndex >= imagens.length) {
      setImgIndex(imgIndex - imagens.length);
    }
  }, [imgIndex]);

  return (
    <div
      style={{
        width: "100%",
      }}
    >
      <div
        style={{
          position: "relative",
          width: "100%",
          height: "580px",
        }}
      >
        {imagens.map((d, idx) => {
          if (Math.floor(idx / 5) !== pageIndex) {
            return <div></div>;
          }
          return (
            <motion.div
              style={{
                position: "absolute",
                top: "0px",
                left: "-20px",
              }}
              animate={{
                translateX:
                  imgIndex === idx
                    ? "75px"
                    : imgIndex > idx
                    ? (idx % 5) * 150 + 75 + "px"
                    : (idx % 5) * 150 - 75 + "px",
                translateY: imgIndex === idx ? "30px" : "430px",
              }}
              transition={SPRING_OPTIONS}
            >
              <motion.div
                key={idx}
                animate={{
                  scale: imgIndex === idx ? 0.95 : 0.75,
                }}
                transition={SPRING_OPTIONS}
                className="aspect-video shrink-0 rounded-xl bg-neutral-800 object-cover"
              >
                <div
                  style={{
                    width: imgIndex === idx ? "80%" : "150px",
                    height: imgIndex === idx ? "80%" : "150px",
                    maxHeight: "400px",
                    borderRadius: "15px",
                    borderStyle: "solid",
                    borderColor: "transparent",
                    overflow: "hidden",
                  }}
                  onClick={() => {
                    setImgIndex(idx);
                  }}
                >
                  <img
                    src={d}
                    style={{
                      objectFit: "cover",
                    }}
                    width="100%"
                    height="100%"
                  />
                </div>
              </motion.div>
            </motion.div>
          );
        })}
        {imgIndex > 0 ? (
          <button
            style={{
              position: "absolute",
              top: "200px",
              left: "30px",
              height: "50px",
              width: "50px",
              borderRadius: "25px",
              backgroundColor: "#456542CC",
              borderStyle: "solid",
              borderColor: "#456542CC",
              color: "white",
              fontSize: "30px",
            }}
            onClick={() => {
              const newIndex = imgIndex - 1;
              setImgIndex(newIndex);
              setPageIndex(Math.floor(newIndex / 5));
            }}
          >
            {"<"}
          </button>
        ) : (
          <div></div>
        )}
        {imgIndex < imagens.length - 1 ? (
          <button
            style={{
              position: "absolute",
              top: "200px",
              left: "90%",
              height: "50px",
              width: "50px",
              borderRadius: "25px",
              backgroundColor: "#456542CC",
              borderStyle: "solid",
              borderColor: "#456542CC",
              color: "white",
              fontSize: "30px",
            }}
            onClick={() => {
              const newIndex = imgIndex + 1;
              setImgIndex(newIndex);
              setPageIndex(Math.floor(newIndex / 5));
            }}
          >
            {">"}
          </button>
        ) : (
          <div></div>
        )}
        {pageIndex > 0 ? (
          <button
            style={{
              position: "absolute",
              top: "480px",
              left: "10px",
              height: "50px",
              width: "50px",
              borderRadius: "25px",
              backgroundColor: "#456542CC",
              borderStyle: "solid",
              borderColor: "#456542CC",
              color: "white",
              fontSize: "30px",
            }}
            onClick={() => {
              const newpage = pageIndex - 1;
              setPageIndex(newpage);
              setImgIndex(newpage * 5);
            }}
          >
            {"<"}
          </button>
        ) : (
          <div></div>
        )}
        {pageIndex < Math.ceil(imagens.length / 5) - 1 ? (
          <button
            style={{
              position: "absolute",
              top: "480px",
              left: "665px",
              height: "50px",
              width: "50px",
              borderRadius: "25px",
              backgroundColor: "#456542CC",
              borderStyle: "solid",
              borderColor: "#456542CC",
              color: "white",
              fontSize: "30px",
            }}
            onClick={() => {
              const newpage = pageIndex + 1;
              setPageIndex(newpage);
              setImgIndex(newpage * 5);
            }}
          >
            {">"}
          </button>
        ) : (
          <div></div>
        )}
      </div>
    </div>
  );
};

interface PropImg {
  imgIndex: number;
  dados: CardProps[];
}
