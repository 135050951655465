import { Ticket } from "./Ticket";

export interface TransactionItemProps {
  image: string;
  typeTransaction: string;
  dateTransaction: string;
  valueTransaction: string;
}

export function TransactionItem({
  image,
  typeTransaction,
  dateTransaction,
  valueTransaction,
}: TransactionItemProps) {
  return (
    <div
      style={{
        display: "flex",
        flexWrap: "wrap",
        width: "100%",
        borderStyle: "solid",
        borderColor: "#EEEEEE",
        borderWidth: "1px",
        margin: "5px",
        paddingLeft: "5px",
        paddingRight: "5px",
      }}
    >
      <div
        style={{
          alignSelf: "center",
          alignContent: "center",
          alignItems: "center",
          paddingTop: "10px",
          width: "50px",
        }}
      >
        <img src={image} />
      </div>
      <div style={{ paddingTop: "10px", width: "200px" }}>
        <div style={{ fontWeight: "bold", fontSize: "14px" }}>
          {typeTransaction}
        </div>
        <div style={{ fontSize: "12px" }}>Data: {dateTransaction}</div>
      </div>
      <div
        style={{
          paddingTop: "15px",
          width: "45px",
        }}
      >
        <div>Value:</div>
      </div>
      <div style={{ paddingTop: "15px", width: "120px" }}>
        <div style={{ whiteSpace: "nowrap", textAlign: "left" }}>
          <b>{valueTransaction}</b>
        </div>
      </div>
    </div>
  );
}
