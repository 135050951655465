import { Messager } from "../pages/Main/MainPage";
import { httpConfig, httpMethod } from "./httpConfig";
import { UserToken } from "./UserToken";

async function doLogin(params, callback) {
  var url = httpConfig.backendUrl + "/login";

  await fetch(url, {
    method: httpMethod.post,
    body: JSON.stringify(params),
    headers: {
      "Content-Type": "application/json",
    },
  })
    .then((response) => {
      if (response.status === 403) {
        Messager.message(
          "Dados Inválidos!",
          "Verifique seu usuário e senha e tente novamente!"
        );
      }
      return response;
    })
    .then((data) => {
      callback(data);
    })
    .catch((error) => {
      console.error("Error:", error);
      callback(error);
    });
}

async function secondStep(params, callback) {
  var url =
    httpConfig.backendUrl +
    "/two_factor" +
    "?code=" +
    params.code +
    "&remind=" +
    params.remind;

  await fetch(url, {
    method: httpMethod.post,
    headers: {
      "Content-Type": "application/json",
      Authorization: UserToken.auth,
    },
  })
    .then((response) => {
      if (response.status === 401 || response.status === 403) {
        document.dispatchEvent(new CustomEvent("logout"));
      }
      return response;
    })
    .then((data) => {
      callback(data);
    })
    .catch((error) => {
      console.error("Error:", error);
      callback(error);
    });
}

async function getProfile(callback) {
  var url = httpConfig.backendUrl + "/client/profile/get";

  await fetch(url, {
    method: httpMethod.get,
    headers: {
      "Content-Type": "application/json",
      Authorization: UserToken.auth,
    },
  })
    .then((response) => {
      if (response.status === 401 || response.status === 403) {
        document.dispatchEvent(new CustomEvent("logout"));
      }
      return response.json();
    })
    .then((data) => {
      callback(data);
    })
    .catch((error) => {
      console.error("Error:", error);
      callback(error);
    });
}

async function setProfile(params, callback) {
  var url = httpConfig.backendUrl + "/client/profile/edit";
  await fetch(url, {
    method: httpMethod.put,
    body: JSON.stringify(params),
    headers: {
      "Content-Type": "application/json",
      Authorization: UserToken.auth,
    },
  })
    .then((response) => {
      if (response.status === 401 || response.status === 403) {
        document.dispatchEvent(new CustomEvent("logout"));
      }
      return response.json();
    })
    .then((data) => {
      callback(data);
    })
    .catch((error) => {
      console.error("Error:", error);
      callback(error);
    });
}
/*
async function setProfileImage(params, callback) {
  var url = httpConfig.backendUrl + "/client/client/profile/picture";
  await fetch(url, {
    method: httpMethod.post,
    body: JSON.stringify(params),
    headers: {
      "Content-Type": "application/json",
      Authorization: UserToken.auth,
    },
  })
    .then((response) => {
      if ((response.status === 401||response.status === 403)) {
        document.dispatchEvent(new CustomEvent("logout"));
      }
      return response.json();
    })
    .then((data) => {
      callback(data);
    })
    .catch((error) => {
      console.error("Error:", error);
      callback(error);
    });
}
*/

async function setProfileImage(params, callback) {
  var url = httpConfig.backendUrl + "/user/profile_picture";

  const formData = new FormData();
  formData.append("file", params);

  await fetch(url, {
    method: httpMethod.patch,
    body: formData,
    headers: {
      Authorization: UserToken.auth,
    },
  })
    .then((response) => {
      if (response.status === 401 || response.status === 403) {
        document.dispatchEvent(new CustomEvent("logout"));
      }
      return response.json();
    })
    .then((data) => {
      callback(data);
    })
    .catch((error) => {
      console.error("Error:", error);
      callback(error);
    });
}

async function checkEmail(params, callback) {
  var url = httpConfig.backendUrl + "/user/has_email_avalible?email=" + params;

  await fetch(url, {
    method: httpMethod.get,
    headers: {},
  })
    .then((response) => {
      return response.status === 200;
    })
    .then((data) => {
      callback(data);
    })
    .catch((error) => {
      console.error("Error:", error);
      callback(error);
    });
}
//

async function getProfileImage(callback) {
  var url = httpConfig.backendUrl + "/user/profile_picture";

  await fetch(url, {
    method: httpMethod.get,
    headers: {
      Authorization: UserToken.auth,
    },
  })
    .then((response) => {
      if (response.status === 401 || response.status === 403) {
        document.dispatchEvent(new CustomEvent("logout"));
      }
      if (response.status === 404) {
        return null;
      }
      return response.blob();
    })
    .then((data) => {
      callback(data);
    })
    .catch((error) => {
      console.error("Error:", error);
      callback(null);
    });
}

async function getImage(id, callback) {
  var url = httpConfig.backendUrl + "/image/get/" + id;

  await fetch(url, {
    method: httpMethod.post,
    headers: {},
  })
    .then((response) => {
      return response.blob();
    })
    .then((data) => {
      callback(data);
    })
    .catch((error) => {
      console.error("Error:", error);
      callback(error);
    });
}

async function getDocStatus(callback) {
  var url = httpConfig.backendUrl + "/document/status";

  await fetch(url, {
    method: httpMethod.get,
    headers: {
      Authorization: UserToken.auth,
    },
  })
    .then((response) => {
      if (response.status === 401 || response.status === 403) {
        document.dispatchEvent(new CustomEvent("logout"));
      }
      return response.json();
    })
    .then((data) => {
      callback(data);
    })
    .catch((error) => {
      console.error("Error:", error);
      callback(error);
    });
}

async function sendDoc(file, type, callback) {
  var url = httpConfig.backendUrl + "/document/send";

  const formData = new FormData();
  formData.append("type", type);
  formData.append("file", file);

  await fetch(url, {
    method: httpMethod.post,
    body: formData,
    headers: {
      Authorization: UserToken.auth,
    },
  })
    .then((response) => {
      if (response.status === 401 || response.status === 403) {
        document.dispatchEvent(new CustomEvent("logout"));
      }
      return response.json();
    })
    .then((data) => {
      callback(data);
    })
    .catch((error) => {
      console.error("Error:", error);
      callback(error);
    });
}

function getAllRaffles(callback) {
  const params = {
    pagination: {
      page: 0,
      size: 20000,
      sortBy: "name",
      sortDir: "ASC",
    },
    data: {
      //name: "",
      //type: "NORMAL",
      //      campaign: "CELLPHONE",
      //      status: "PENDING",
      //isActive: true,
      drawDateTimeStart: "2023-07-18T08:39:51.280Z",
      drawDateTimeEnd: "2125-07-19T08:39:51.280Z",
    },
  };
  searchRaffles(params, callback);
}

export class CampaignType {
  static NORMAL = "NORMAL";
  static FEATURED = "FEATURED";
  static FLASH = "FLASH";
}

function getAllRafflesByType(campaigntype, callback) {
  const params = {
    pagination: {
      page: 0,
      size: 20000,
      sortBy: "name",
      sortDir: "ASC",
    },
    data: {
      //name: "",
      type: campaigntype,
      //      campaign: "CELLPHONE",
      //      status: "PENDING",
      //isActive: true,
      drawDateTimeStart: "2023-07-18T08:39:51.280Z",
      drawDateTimeEnd: "2125-07-19T08:39:51.280Z",
    },
  };
  searchRaffles(params, callback);
}

export class CampaignCategory {
  static CELLPHONE = "CELLPHONE";
  static CPF = "CPF";
  static CNPJ = "CNPJ";
  static CAR_PLACE = "CAR_PLACE";
  static REAL_STATE = "REAL_STATE";
  static translate(raw) {
    switch (raw) {
      case "Celular":
        return this.CELLPHONE;
      case "CPF":
        return this.CPF;
      case "CNPJ":
        return this.CNPJ;
      case "Carro":
        return this.CAR_PLACE;
      case "Insc.Mob.":
        return this.REAL_STATE;
    }
  }
}

function getAllCampaignRaffles(campaigncategory, callback) {
  const params = {
    pagination: {
      page: 0,
      size: 20000,
      sortBy: "name",
      sortDir: "ASC",
    },
    data: {
      //name: "",
      //type: "FEATURED",
      campaign: campaigncategory, //CELLPHONE, CPF, CNPJ, CAR_PLACE, REAL_STATE
      //      status: "PENDING",
      //isActive: true,
      drawDateTimeStart: "2023-07-18T08:39:51.280Z",
      drawDateTimeEnd: "2125-07-19T08:39:51.280Z",
    },
  };
  searchRaffles(params, callback);
}

function getFlashCampaign(callback) {
  const dt = new Date();
  dt.setFullYear(dt.getFullYear() + 1);
  const params = {
    pagination: {
      page: 0,
      size: 1,
      sortBy: "name",
      sortDir: "ASC",
    },
    data: {
      //name: "",
      type: "FLASH",
      //campaign: campaigncategory, //CELLPHONE, CPF, CNPJ, CAR_PLACE, REAL_STATE
      //      status: "PENDING",
      //isActive: true,
      drawDateTimeStart: new Date(),
      drawDateTimeEnd: dt,
    },
  };
  searchRaffles(params, callback);
}

function getLatestWinners(callback) {
  const params = {
    pagination: {
      page: 0,
      size: 1,
      sortBy: "drawDateTime",
      sortDir: "DESC",
    },
    data: {
      status: "COMPLETED",
    },
  };
  searchRaffles(params, callback);
}

async function searchRaffles(params, callback) {
  var url = httpConfig.backendUrl + "/raffle/search/all";

  await fetch(url, {
    method: httpMethod.post,
    body: JSON.stringify(params),
    headers: {
      "Content-Type": "application/json",
    },
  })
    .then((response) => {
      return response.json();
    })
    .then((data) => {
      if (data) {
        callback(data);
      }
    })
    .catch((error) => {
      console.error("Error:", error);
      callback(error);
    });
}

function getHistory(callback) {
  const dt = new Date();
  dt.setFullYear(dt.getFullYear() - 1);
  const params = {
    pagination: {
      page: 0,
      size: 10000,
      sortBy: "id",
      sortDir: "DESC",
    },
    data: {
      dateStart: dt,
      dateEnd: new Date(),
    },
  };
  searchHistory(params, callback);
}

async function searchHistory(params, callback) {
  var url = httpConfig.backendUrl + "/history/search";

  await fetch(url, {
    method: httpMethod.post,
    body: JSON.stringify(params),
    headers: {
      Authorization: UserToken.auth,
      "Content-Type": "application/json",
    },
  })
    .then((response) => {
      return response.json();
    })
    .then((data) => {
      if (data) {
        callback(data);
      }
    })
    .catch((error) => {
      console.error("Error:", error);
      callback(error);
    });
}

async function searchRafflesByID(id, callback) {
  var url = httpConfig.backendUrl + "/raffle/" + id;

  await fetch(url, {
    method: httpMethod.get,
    headers: {
      "Content-Type": "application/json",
    },
  })
    .then((response) => {
      return response.json();
    })
    .then((data) => {
      if (data) {
        callback(data);
      }
    })
    .catch((error) => {
      console.error("Error:", error);
      callback(error);
    });
}

function getAllCouponsFromRaffle(id, callback) {
  const params = {
    pagination: {
      page: 0,
      size: 20000,
      sortBy: "raffleId",
      sortDir: "ASC",
    },
    data: {
      raffleId: id,
      raffleIsActive: true,
    },
  };
  searchCupoms(params, callback);
}

function getAllCouponsFromUser(callback) {
  const params = {
    pagination: {
      page: 0,
      size: 20000,
      sortBy: "raffleId",
      sortDir: "ASC",
    },
    data: {},
  };
  searchCupoms(params, callback);
}

async function searchCupoms(params, callback) {
  var url = httpConfig.backendUrl + "/coupon/search";

  await fetch(url, {
    method: httpMethod.post,
    body: JSON.stringify(params),
    headers: {
      Authorization: UserToken.auth,
      "Content-Type": "application/json",
    },
  })
    .then((response) => {
      if (response.status === 401 || response.status === 403) {
        document.dispatchEvent(new CustomEvent("logout"));
      }
      return response.json();
    })
    .then((data) => {
      if (data) {
        callback(data);
      }
    })
    .catch((error) => {
      console.error("Error:", error);
      callback(error);
    });
}

async function searchWinnerRafflesFromUser(params, callback) {
  var url = httpConfig.backendUrl + "/prize-redeem/search";

  await fetch(url, {
    method: httpMethod.post,
    body: JSON.stringify(params),
    headers: {
      Authorization: UserToken.auth,
      "Content-Type": "application/json",
    },
  })
    .then((response) => {
      if (response.status === 401 || response.status === 403) {
        document.dispatchEvent(new CustomEvent("logout"));
      }
      return response.json();
    })
    .then((data) => {
      if (data) {
        callback(data);
      }
    })
    .catch((error) => {
      console.error("Error:", error);
      callback(error);
    });
}

async function requestPrize(idPrize, file, params, callback) {
  var url = httpConfig.backendUrl + "/prize-redeem/"+idPrize+"/request";

  const formData = new FormData();

  const blob = new Blob([JSON.stringify(params)], {
    type: 'application/json'
  });
  formData.append("data", blob);
  formData.append("campaignImage", file);


  await fetch(url, {
    method: httpMethod.post,
    body: formData,
    headers: {
      Authorization: UserToken.auth,
    },
  })
    .then((response) => {
      if (response.status === 401 || response.status === 403) {
        document.dispatchEvent(new CustomEvent("logout"));
      }
      return response.json();
    })
    .then((data) => {
      if (data) {
        callback(data);
      }
    })
    .catch((error) => {
      console.error("Error:", error);
      callback(error);
    });
}
async function searchUserRaffle(params, callback) {
  var url = httpConfig.backendUrl + "/raffle/search";

  await fetch(url, {
    method: httpMethod.post,
    body: JSON.stringify(params),
    headers: {
      Authorization: UserToken.auth,
      "Content-Type": "application/json",
    },
  })
    .then((response) => {
      if (response.status === 401 || response.status === 403) {
        document.dispatchEvent(new CustomEvent("logout"));
      }
      return response.json();
    })
    .then((data) => {
      if (data) {
        callback(data);
      }
    })
    .catch((error) => {
      console.error("Error:", error);
      callback(error);
    });
}

async function getAllUserRaffle(callback) {
  const params = {
    pagination: {
      page: 0,
      size: 20000,
      sortBy: "drawDateTime",
      sortDir: "ASC",
    },
    data: {
      /*
      name: "string",
      type: "NORMAL",
      campaign: "CELLPHONE",
      status: "PENDING",
      isActive: true,
      drawDateTimeStart: "2024-08-02T08:56:01.390Z",
      drawDateTimeEnd: "2024-08-02T08:56:01.390Z",
      prizeType: "AIRPLANE"
      */
    },
  };
  searchUserRaffle(params, callback);
}

async function getWinnerRafflesFromUser(callback){
  const params = {
    pagination: {
      page: 0,
      size: 20000,
      sortBy: "drawDateTime",
      sortDir: "ASC",
    },
    data: {
      /*
      name: "string",
      type: "NORMAL",
      campaign: "CELLPHONE",
      status: "PENDING",
      isActive: true,
      drawDateTimeStart: "2024-08-02T08:56:01.390Z",
      drawDateTimeEnd: "2024-08-02T08:56:01.390Z",
      prizeType: "AIRPLANE"
      */
    },
  };
  searchUserRaffle(params, callback);
}

async function checkWallet(callback) {
  var url = httpConfig.backendUrl + "/wallet/BRL";

  await fetch(url, {
    method: httpMethod.post,
    headers: {
      Authorization: UserToken.auth,
      "Content-Type": "application/json",
    },
  })
    .then((response) => {
      return response.json();
    })
    .then((data) => {
      if (data) {
        callback(data);
      }
    })
    .catch((error) => {
      console.error("Error:", error);
      callback(error);
    });
}

async function changePassword(pass, newpass, callback) {
  var url = httpConfig.backendUrl + "/user/change_password";

  await fetch(url, {
    method: httpMethod.post,
    body: JSON.stringify({
      password: newpass,
      oldPassword: pass,
    }),
    headers: {
      Authorization: UserToken.auth,
      "Content-Type": "application/json",
    },
  })
    .then((response) => {
      return response.json();
    })
    .then((data) => {
      if (data) {
        callback(data);
      }
    })
    .catch((error) => {
      console.error("Error:", error);
      callback(error);
    });
}

async function requestDeposit(method, amount, callback) {
  var url = httpConfig.backendUrl + "/deposit";

  await fetch(url, {
    method: httpMethod.post,
    body: JSON.stringify({
      method: method,
      value: amount,
    }),
    headers: {
      Authorization: UserToken.auth,
      "Content-Type": "application/json",
    },
  })
    .then((response) => {
      return response.json();
    })
    .then((data) => {
      if (data) {
        callback(data);
      }
    })
    .catch((error) => {
      console.error("Error:", error);
      callback(error);
    });
}

async function checkCupons(callback) {
  var url = httpConfig.backendUrl + "/coupon/dashboard";

  await fetch(url, {
    method: httpMethod.post,
    headers: {
      Authorization: UserToken.auth,
      "Content-Type": "application/json",
    },
  })
    .then((response) => {
      return response.json();
    })
    .then((data) => {
      if (data) {
        callback(data);
      }
    })
    .catch((error) => {
      console.error("Error:", error);
      callback(error);
    });
}

//

async function buyCupom(raffleId, codes, callback) {
  var url = httpConfig.backendUrl + "/raffle/" + raffleId + "/buy";

  await fetch(url, {
    method: httpMethod.post,
    body: JSON.stringify({ coupons: codes }),
    headers: {
      Authorization: UserToken.auth,
      "Content-Type": "application/json",
    },
  })
    .then((response) => {
      return response.json();
    })
    .then((data) => {
      if (data) {
        callback(data);
      }
    })
    .catch((error) => {
      console.error("Error:", error);
      callback(null);
    });
}

export class DocType {
  static shelfie = "SHELFIE";
  static address = "ADDRESS";
  static doc = "DOC";
}

export {
  doLogin,
  secondStep,
  getProfile,
  setProfile,
  setProfileImage,
  getProfileImage,
  getDocStatus,
  sendDoc,
  getAllRaffles,
  getAllRafflesByType,
  getAllCampaignRaffles,
  getImage,
  getAllCouponsFromRaffle,
  getAllCouponsFromUser,
  buyCupom,
  checkWallet,
  searchRafflesByID,
  getLatestWinners,
  getAllUserRaffle,
  checkCupons,
  changePassword,
  requestDeposit,
  getFlashCampaign,
  getHistory,
  checkEmail,
  getWinnerRafflesFromUser,
  searchWinnerRafflesFromUser,
  requestPrize,
};
