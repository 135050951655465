import { useEffect, useRef, useState } from "react";
import { requestDeposit } from "../../../services/login";
import { format } from "../../../utils/Utils";
import { BlackTicket } from "../Components/BlackTicket";
import { CheckBox } from "../Components/CheckBox";
import { Selection } from "../Components/Selection";
import { InputValue } from "../Components/InputValue";
import { RoundInput } from "../Components/RoundInput";
import { Raffle } from "../PaginaInicio";
import { useAuth } from "../../../contexts/AuthContext";
import { Messager } from "../MainPage";

export interface Props {
  setModalOpen: React.Dispatch<React.SetStateAction<boolean>>;
}

export function ModalDeposito({ setModalOpen }: Props) {
  const modalContainerRef = useRef<HTMLDivElement | null>(null);
  const [metodo, setMetodo] = useState(3);
  const [valor, setValor] = useState(5);
  const { hasPermission } = useAuth();

  useEffect(() => {
    const handleClickOutside = (event: MouseEvent) => {
      if (
        modalContainerRef.current &&
        !modalContainerRef.current.contains(event.target as Node)
      ) {
        setModalOpen(false);
      }
    };

    // Adicione o ouvinte de evento quando o componente for montado
    document.addEventListener("mousedown", handleClickOutside);

    // Remova o ouvinte de evento quando o componente for desmontado
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, [setModalOpen]);
  if (!hasPermission("ROLE_CLIENT")) {
    Messager.message(
      "Login Necessário",
      "É necessário estar logado para realizar uma compra."
    );
    setModalOpen(false);
  }
  return (
    <div>
      <div
        style={{
          zIndex: "2500",
          position: "fixed",
          top: "0px",
          left: "0px",
          width: "100%",
          height: "100%",
          backgroundColor: "#00000088",
          overflow: "hidden",
        }}
      ></div>
      <div
        style={{
          zIndex: "3000",
          position: "fixed",
          top: "100px",
          left: "Calc(50% - 200px)",
          width: "400px",
          height: "520px",
          backgroundColor: "#EEE9E3",
          borderRadius: "20px",
          borderColor: "transparent",
          borderStyle: "solid",
          overflow: "hidden",
          fontSize: "30px",
        }}
        ref={modalContainerRef}
      >
        <div
          style={{ paddingLeft: "50px", paddingRight: "50px", color: "black" }}
        >
          <div
            style={{
              display: "flex",
              justifyContent: "space-between",
              paddingTop: "20px",
            }}
          >
            <div></div>
            <img src="g.png" />
            <div></div>
          </div>
          <div
            style={{ fontSize: "20px", textAlign: "center", color: "black" }}
          >
            <b>Método de pagamento</b>
          </div>
          <hr
            style={{ borderWidth: "2px", borderStyle: "solid", color: "black" }}
          />

          <Selection
            title={"Método de Pagamento:"}
            buttons={["Boleto", "Cartão de Débito", "Cartão de Crédito", "Pix"]}
            selectedIndex={metodo}
            setSelectedIndex={setMetodo}
          />

          <InputValue
            label={"Valor"}
            type={"money"}
            id={"tfvalor"}
            labelFontSize={19}
            bold
            width={250}
            defaultValue={"R$ 5,00"}
            setNumber={setValor}
          />

          <div style={{ display: "flex", justifyContent: "space-between" }}>
            <div></div>
            <div
              style={{
                backgroundColor: "green",
                color: "white",
                justifyContent: "space-between",
                width: "50%",
                margin: "20px",
                fontSize: "20px",
                padding: "5px",
                paddingRight: "30px",
                paddingLeft: "30px",
                borderRadius: "45px",
                textAlign: "center",
                cursor: "pointer",
              }}
              onClick={() => {
                const metodos = ["BILLET", "DEBIT_CARD", "CREDIT_CARD", "PIX"];
                requestDeposit(metodos[metodo], valor, (resp: any) => {
                  if (resp.url) {
                    window.open(resp.url, "_blank")?.focus();
                  }
                });
              }}
            >
              COMPRAR
            </div>
            <div></div>
          </div>
        </div>
      </div>
    </div>
  );
}
