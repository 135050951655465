import { useRef, useState } from "react";
import "./RoundInput.css";

export interface Props {
  label: string;
  type: string;
  id: string;
  subtext?: string;
  subtextsize?: string;
  defaultValue?: number | string;
  editable?: boolean;
  width?: string;
  length?: number;
  textArea?: boolean;
  borderColor?: string;
  fontSize?:number;
}

export function RectInput({
  label,
  type,
  id,
  subtext,
  subtextsize,
  defaultValue,
  editable = true,
  width = "550px",
  length,
  textArea = false,
  borderColor = "#cccccc",
  fontSize = 14,
}: Props) {
  const [showPass, setShowPass] = useState<boolean>(false);
  const inputfield = useRef<HTMLInputElement | null>(null);

  const handleCpfCnpjChange = (event: any) => {
    if (length) {
      if (event.target.value.length > length) {
        event.target.value = event.target.value.substr(0, length);
      }
    }

    if (type === "cpfcnpj") {
      // Get only the numbers from the data input
      let data = event.target.value.replace(/\D/g, "");
      // Checking data length to define if it is cpf or cnpj
      if (data.length > 11) {
        // It's cnpj
        let cnpj = `${data.substr(0, 2)}.${data.substr(2, 3)}.${data.substr(
          5,
          3
        )}/`;
        if (data.length > 12)
          cnpj += `${data.substr(8, 4)}-${data.substr(12, 2)}`;
        else cnpj += data.substr(8);
        // Pass formatting for the data
        data = cnpj;
      } else {
        // It's cpf
        let cpf = "";
        let parts = Math.ceil(data.length / 3);
        for (let i = 0; i < parts; i++) {
          if (i === 3) {
            cpf += `-${data.substr(i * 3)}`;
            break;
          }
          cpf += `${i !== 0 ? "." : ""}${data.substr(i * 3, 3)}`;
        }
        // Pass formatting for the data
        data = cpf;
      }
      if (inputfield.current) {
        inputfield.current.value = data;
      }
    } else if (type === "cpf") {
      // Get only the numbers from the data input
      let data = event.target.value.replace(/\D/g, "");

      if (data.length > 11) {
        data = data.substr(0, 11);
      }

      let cpf = "";
      let parts = Math.ceil(data.length / 3);
      for (let i = 0; i < parts; i++) {
        if (i === 3) {
          cpf += `-${data.substr(i * 3)}`;
          break;
        }
        cpf += `${i !== 0 ? "." : ""}${data.substr(i * 3, 3)}`;
      }

      if (inputfield.current) {
        inputfield.current.value = cpf;
      }
    } else if (type === "card") {
      // Get only the numbers from the data input
      let data = event.target.value.replace(/\D/g, "");

      if (data.length > 16) {
        data = data.substr(0, 16);
      }

      let card = "";
      let parts = Math.ceil(data.length / 4);
      for (let i = 0; i < parts; i++) {
        card += `${i !== 0 ? "." : ""}${data.substr(i * 4, 4)}`;
      }

      if (inputfield.current) {
        inputfield.current.value = card;
      }
    }
  };

  return (
    <div
      style={{
        display: "flex",
        textAlign: "left",
        fontSize: fontSize+"px",
        paddingBottom: "20px",
        justifyContent: "space-between",
      }}
    >
      <div></div>
      <div
        style={{
          textAlign: "left",
          fontSize: fontSize+"px",
          width: width,
        }}
      >
        <div>{label}</div>
        <div
          style={{
            borderStyle: "solid",
            paddingTop: "10px",
            paddingBottom: "10px",
            display: "flex",
            borderWidth: "1px",
            backgroundColor: "white",
            borderColor: borderColor,
          }}
        >
          {textArea ? (
            <textarea
              id={id}
              style={{
                background: "transparent",
                backgroundColor: "transparent",
                borderStyle: "none",
                width: "100%",
                paddingLeft: "20px",
                outline: "none",
                outlineColor: "transparent",
                accentColor: "transparent",
                color: "black",
                lightingColor: "transparent",
                resize: "none",
                fontSize:fontSize+"px",
              }}
              rows={7}
              placeholder="Escreva sua mensagem aqui"
            >
              {defaultValue}
            </textarea>
          ) : editable ? (
            <input
              id={id}
              type={
                type === "password" ? (showPass ? "text" : "password") : type
              }
              style={{
                background: "transparent",
                backgroundColor: "transparent",
                borderStyle: "none",
                width: "100%",
                paddingLeft: "20px",
                outline: "none",
                outlineColor: "transparent",
                accentColor: "transparent",
                color: "black",
                lightingColor: "transparent",
                fontSize:fontSize+"px",
              }}
              defaultValue={defaultValue}
              onChange={handleCpfCnpjChange}
              ref={inputfield}
            />
          ) : (
            <input
              id={id}
              type={
                type === "password" ? (showPass ? "text" : "password") : type
              }
              style={{
                background: "transparent",
                backgroundColor: "transparent",
                borderStyle: "none",
                width: "100%",
                paddingLeft: "20px",
                outline: "none",
                outlineColor: "transparent",
                accentColor: "transparent",
                color: "black",
                lightingColor: "transparent",
                fontSize:fontSize+"px",
              }}
              value={defaultValue}
              readOnly={true}
            />
          )}
          {type === "password" ? (
            <button
              style={{
                backgroundColor: "transparent",
                borderStyle: "none",
              }}
              onClick={() => {
                setShowPass(!showPass);
              }}
              type="button"
            >
              <img
                src={showPass ? "eye.png" : "hide.png"}
                width="15px"
                style={{ paddingRight: "10px", fontSize:fontSize+"px",}}
              />
            </button>
          ) : (
            <div />
          )}
        </div>
        {subtext === undefined ? (
          <div />
        ) : (
          <div
            style={{
              fontSize: subtextsize === undefined ? "8px" : subtextsize,
              paddingTop: "3px",
            }}
          >
            {subtext}
          </div>
        )}
      </div>
      <div></div>
    </div>
  );
}
