interface Props {
  label: string;
  link: string;
  fontSize?: number;
  linkColor?: string;
  linkAction: () => void;
  id: string;
}
export function CheckBox({ label, link, linkColor, linkAction, id, fontSize=12 }: Props) {
  return (
    <div style={{ fontSize: fontSize+"px", display: "flex" }}>
      <input type="checkbox" id={id} />
      <div style={{ fontSize: fontSize+"px", paddingTop:(12-fontSize)+"px"}}>{label}</div>
      <button
        onClick={linkAction}
        style={{
          backgroundColor: "transparent",
          borderStyle: "none",
          color: linkColor ? linkColor : "white",
          textAlign: "left",
          fontSize:fontSize+"px",
        }}
      >
        <u>{link}</u>
      </button>
    </div>
  );
}
