interface Props {
  topline: string;
  bottomline: string;
}

export function BlackTicket({ topline, bottomline }: Props) {
  return (
    <div style={{ position: "relative" }}>
      <div
        style={{
          position: "absolute",
          top: "20px",
          left: "10px",
          fontSize: "20px",
          color: "white",
          fontWeight: "bold",
          width: "180px",
          textAlign: "center",
        }}
      >
        {topline}
      </div>
      <div
        style={{
          position: "absolute",
          top: "50px",
          left: "10px",
          fontSize: "16px",
          color: "#B7B19D",
          textAlign: "center",
          width: "180px",
        }}
      >
        {bottomline}
      </div>

      <svg
        xmlns="http://www.w3.org/2000/svg"
        width={"200"}
        height="100"
        viewBox={"0 0 354 120"}
        fill="none"
      >
        <mask
          id="path-1-outside-1_869_3231"
          maskUnits="userSpaceOnUse"
          x="0"
          y="0"
          width="100%"
          fill="black"
        >
          <rect fill="white" width="100%" height="100%" />
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M1 16.7192C8.40258 15.315 14 8.81117 14 1H340C340 8.51785 345.185 14.8247 352.174 16.5396V103.46C345.239 105.162 340.08 111.385 340.001 118.826H13.9991C13.9167 111.092 8.34757 104.675 1 103.281V16.7192Z"
          />
        </mask>
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M1 16.7192C8.40258 15.315 14 8.81117 14 1H340C340 8.51785 345.185 14.8247 352.174 16.5396V103.46C345.239 105.162 340.08 111.385 340.001 118.826H13.9991C13.9167 111.092 8.34757 104.675 1 103.281V16.7192Z"
          fill="#000000"
        />
        <path
          d="M14 1V0H13V1H14ZM1 16.7192L0.81363 15.7367L0 15.8911V16.7192H1ZM340 1H341V0H340V1ZM352.174 16.5396H353.174V15.7554L352.412 15.5685L352.174 16.5396ZM352.174 103.46L352.412 104.432L353.174 104.245V103.46H352.174ZM340.001 118.826V119.826H340.99L341.001 118.837L340.001 118.826ZM13.9991 118.826L12.9991 118.837L13.0097 119.826H13.9991V118.826ZM1 103.281H0V104.109L0.81363 104.263L1 103.281ZM13 1C13 8.32187 7.75299 14.4204 0.81363 15.7367L1.18637 17.7017C9.05217 16.2096 15 9.30047 15 1H13ZM14 2H340V0H14V2ZM339 1C339 8.98905 344.51 15.6887 351.936 17.5108L352.412 15.5685C345.86 13.9607 341 8.04666 341 1H339ZM351.174 16.5396V103.46H353.174V16.5396H351.174ZM351.936 102.489C344.567 104.297 339.085 110.908 339.001 118.815L341.001 118.837C341.075 111.862 345.911 106.027 352.412 104.432L351.936 102.489ZM340.001 117.826H13.9991V119.826H340.001V117.826ZM14.999 118.815C14.9115 110.597 8.99378 103.779 1.18637 102.298L0.81363 104.263C7.70136 105.57 12.9219 111.588 12.9991 118.837L14.999 118.815ZM2 103.281V16.7192H0V103.281H2Z"
          fill="#000000"
          mask="url(#path-1-outside-1_869_3231)"
        />
      </svg>
    </div>
  );
  /*
  return (
    <div style={{ padding: "30px", backgroundColor: "red" }}>
      <div
        style={{
          backgroundColor: "white",
          borderRadius: "10px",
          maxWidth: "300px",
          alignContent: "center",
          alignItems: "center",
          textAlign: "center",
        }}
      >
        <p>
          <div
            style={{
              color: "green",
              display: "flex",
              alignItems: "center",
              alignContent: "center",
              alignSelf: "center",
              justifyContent: "center",
            }}
          >
            <img src={icon} /> {title}
          </div>
        </p>
        <hr color="green" style={{ width: "50%" }}></hr>
        <div
          style={{
            paddingBottom: "15px",
            fontSize: "10px",
            paddingLeft: "20px",
            paddingRight: "20px",
          }}
        >
          {line}
        </div>
      </div>
    </div>
  );*/
}
