import sample from "../../../assets/videos/numberwinv3.mp4";

import "../PaginaInicio.css";
import "../specialeffects.css";


export interface RegisterData {
  nome: string;
  sobrenome: string;
  senha: string;
  email: string;
  telefone: string;
  cpf: string;
  dia: string;
  mes: string;
  ano: string;
}

export function PaginaTermos() {
  return (
    <div style={{ backgroundColor: "white" }}>
      <div style={{ position: "relative" }}>
        <div style={{ height: "600px", overflow: "hidden" }}>
          <video
            className="videoTag"
            style={{ height: "100%" }}
            autoPlay
            loop
            muted
          >
            <source src={sample} type="video/mp4" />
          </video>
        </div>
        <div
          style={{
            position: "absolute",
            bottom: "0px",
            left: "0px",
            width: "100%",
            height: "100%",
            backgroundColor: "#00000022",
          }}
        ></div>
      </div>
      <div style={{ position: "relative" }}>
        <div
          style={{
            position: "absolute",
            top: "-180px",
            left: "0px",
            width: "100%",
            height: "100%",
          }}
        >
          <div
            style={{
              fontSize: window.innerWidth<1000?36:50,
              color: "white",
              paddingTop: "0px",
              paddingLeft: "12%",
              fontWeight: "bold",
              cursor: "pointer",
            }}
            onClick={() => {
              window
                .open(
                  window.location.href.substring(
                    0,
                    window.location.href.indexOf("?")
                  ) + "?pag=6",
                  "_blank"
                )
                ?.focus();
            }}
          >
            Termos de Uso e
          </div>
          <div
            style={{
              fontSize: window.innerWidth<1000?36:50,
              color: "white",
              paddingTop: "10px",
              paddingLeft: "12%",
              fontWeight: "bold",
              paddingBottom: "20px",
              cursor: "pointer",
            }}
            onClick={() => {
              window
                .open(
                  window.location.href.substring(
                    0,
                    window.location.href.indexOf("?")
                  ) + "?pag=6",
                  "_blank"
                )
                ?.focus();
            }}
          >
            Política de Privacidade
          </div>
        </div>
        <div style={{ backgroundColor: "white", color: "black" }}>
          <div style={{ display: "flex" }}>
            <div
              style={{
                paddingLeft: "12%",
                fontSize: "16px",
                minWidth: "60%",
                maxWidth: "60%",
                paddingRight: "30px",
              }}
            >
              <br />
              <br />
              <b>The standard Lorem Ipsum passage, used since the 1500s</b>
              {""}
              <br />
              "Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do
              eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut
              enim ad minim veniam, quis nostrud exercitation ullamco laboris
              nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in
              reprehenderit in voluptate velit esse cillum dolore eu fugiat
              nulla pariatur. Excepteur sint occaecat cupidatat non proident,
              sunt in culpa qui officia deserunt mollit anim id est laborum."
              <br />
              <br />
              <b>
                Section 1.10.32 of "de Finibus Bonorum et Malorum", written by
                Cicero in 45 BC{""}
              </b>
              <br />
              "Sed ut perspiciatis unde omnis iste natus error sit voluptatem
              accusantium doloremque laudantium, totam rem aperiam, eaque ipsa
              quae ab illo inventore veritatis et quasi architecto beatae vitae
              dicta sunt explicabo. Nemo enim ipsam voluptatem quia voluptas sit
              aspernatur aut odit aut fugit, sed quia consequuntur magni dolores
              eos qui ratione voluptatem sequi nesciunt. Neque porro quisquam
              est, qui dolorem ipsum quia dolor sit amet, consectetur, adipisci
              velit, sed quia non numquam eius modi tempora incidunt ut labore
              et dolore magnam aliquam quaerat voluptatem. Ut enim ad minima
              veniam, quis nostrum exercitationem ullam corporis suscipit
              laboriosam, nisi ut aliquid ex ea commodi consequatur? Quis autem
              vel eum iure reprehenderit qui in ea voluptate velit esse quam
              nihil molestiae consequatur, vel illum qui dolorem eum fugiat quo
              voluptas nulla pariatur?"
              <br />
              <br />
              <b> 1914 translation by H. Rackham </b>
              <br />
              "But I must explain to you how all this mistaken idea of
              denouncing pleasure and praising pain was born and I will give you
              a complete account of the system, and expound the actual teachings
              of the great explorer of the truth, the master-builder of human
              happiness. No one rejects, dislikes, or avoids pleasure itself,
              because it is pleasure, but because those who do not know how to
              pursue pleasure rationally encounter consequences that are
              extremely painful. Nor again is there anyone who loves or pursues
              or desires to obtain pain of itself, because it is pain, but
              because occasionally circumstances occur in which toil and pain
              can procure him some great pleasure. To take a trivial example,
              which of us ever undertakes laborious physical exercise, except to
              obtain some advantage from it? But who has any right to find fault
              with a man who chooses to enjoy a pleasure that has no annoying
              consequences, or one who avoids a pain that produces no resultant
              pleasure?"
              <br />
              <br />
              <b>
                Section 1.10.33 of "de Finibus Bonorum et Malorum", written by
                Cicero in 45 BC{""}
              </b>
              {""}
              <br />
              "At vero eos et accusamus et iusto odio dignissimos ducimus qui
              blanditiis praesentium voluptatum deleniti atque corrupti quos
              dolores et quas molestias excepturi sint occaecati cupiditate non
              provident, similique sunt in culpa qui officia deserunt mollitia
              animi, id est laborum et dolorum fuga. Et harum quidem rerum
              facilis est et expedita distinctio. Nam libero tempore, cum soluta
              nobis est eligendi optio cumque nihil impedit quo minus id quod
              maxime placeat facere possimus, omnis voluptas assumenda est,
              omnis dolor repellendus. Temporibus autem quibusdam et aut
              officiis debitis aut rerum necessitatibus saepe eveniet ut et
              voluptates repudiandae sint et molestiae non recusandae. Itaque
              earum rerum hic tenetur a sapiente delectus, ut aut reiciendis
              voluptatibus maiores alias consequatur aut perferendis doloribus
              asperiores repellat."
              <br />
              <br /> <b>1914 translation by H. Rackham</b>
              {""}
              <br />" On the other hand, we denounce with righteous indignation
              and dislike men who are so beguiled and demoralized by the charms
              of pleasure of the moment, so blinded by desire, that they cannot
              foresee the pain and trouble that are bound to ensue; and equal
              blame belongs to those who fail in their duty through weakness of
              will, which is the same as saying through shrinking from toil and
              pain. These cases are perfectly simple and easy to distinguish. In
              a free hour, when our power of choice is untrammelled and when
              nothing prevents our being able to do what we like best, every
              pleasure is to be welcomed and every pain avoided. But in certain
              circumstances and owing to the claims of duty or the obligations
              of business it will frequently occur that pleasures have to be
              repudiated and annoyances accepted. The wise man therefore always
              holds in these matters to this principle of selection: he rejects
              pleasures to secure other greater pleasures, or else he endures
              pains to avoid worse pains."
              <br />
              <br />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
