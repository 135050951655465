import { useEffect, useState } from "react";
import { Card, CardProps } from "./Card";
import { DropdownMenuButton } from "./DropdownMenuButton";
import { DropdownSelectionButton } from "./DropdownSelectionButton";
import { DropdownSelectionButton_M } from "./DropdownSelectionButton_M";
import { Card_M } from "./Card_M";

interface Props {
  data: CardProps[];
  buttons: ButtonsProps[];
  btLabel?: string;
}

interface ButtonsProps {
  label: string;
  onClick: () => void;
  isSelected: () => boolean;
}

export function ResultsGrid_M({ data, buttons, btLabel }: Props) {
  const [filter, setFilter] = useState(-1);
  const [category, setCategory] = useState(-1);
  const [search, setSearch] = useState("");

  var tmp = [];
  if (filter > -1) {
    var dateStart = new Date();
    dateStart.setHours(0, 0, 0, 0);
    var dateEnd = new Date();
    dateEnd.setHours(0, 0, 0, 0);
    switch (filter) {
      case 0:
        dateEnd.setDate(dateEnd.getDate() + 1);
        break;
      case 1:
        dateEnd.setDate(dateEnd.getDate() + 2);
        break;
      case 2:
        dateStart.setDate(dateStart.getDate() - dateStart.getDay());
        dateEnd.setDate(dateEnd.getDate() - dateEnd.getDay() + 7);
        break;
      case 3:
        dateStart.setDate(dateStart.getDate() - dateStart.getDay() + 7);
        dateEnd.setDate(dateEnd.getDate() - dateEnd.getDay() + 14);
        break;
      case 4:
        dateStart.setDate(1);
        dateEnd.setDate(1);
        const month = dateEnd.getMonth();
        dateEnd.setMonth(month + 1);
        break;
    }
    for (let index = 0; index < data.length; index++) {
      const element = data[index];
      const drawdate = new Date(element.raffle.drawDateTime);
      if (drawdate > dateStart && drawdate < dateEnd) {
        tmp.push(element);
      }
    }
  } else {
    tmp = [...data];
  }
  var tmp2 = [];

  if (search && search.length > 0) {
    for (let index = 0; index < tmp.length; index++) {
      const element = tmp[index];
      if (
        element.raffle.campaign.indexOf(search) !== -1 ||
        element.raffle.description?.indexOf(search) !== -1 ||
        element.raffle.name.indexOf(search) !== -1
      ) {
        tmp2.push(element);
      }
    }
  } else {
    tmp2 = [...tmp];
  }
  var results: CardProps[] = [];
  if (category > -1) {
    /*
    AIRPLANE,
    SHIP,
    CRYPTO,
    REAL_STATE,
    TRUCK,
    AUTOMOBILE
    */
    var type = "";
    switch (category) {
      case 0:
        type = "AIRPLANE";
        break;
      case 1:
        type = "SHIP";
        break;
      case 2:
        type = "CRYPTO";
        break;
      case 3:
        type = "REAL_STATE";
        break;
      case 4:
        type = "TRUCK";
        break;
      case 5:
        type = "AUTOMOBILE";
        break;
    }
    if (type.length > 0) {
      for (let index = 0; index < tmp2.length; index++) {
        const element = tmp2[index];
        for (let index2 = 0; index2 < element.raffle.prizes.length; index2++) {
          const subelement = element.raffle.prizes[index2];
          if (subelement.type === type) {
            results.push(element);
            break;
          }
        }
      }
    } else {
      results = [...tmp2];
    }
  } else {
    results = [...tmp2];
  }

  return (
    <div>
      <div
        style={{
          display: "flex",
          paddingLeft: "15px",
          width: "calc(100% - 30px)",
        }}
      >
        <DropdownSelectionButton_M
          title={"Filter"}
          buttons={[
            "Hoje",
            "Amanhã",
            "Essa Semana",
            "Próxima Semana",
            "Este Mês",
          ]}
          selectedIndex={filter}
          setSelectedIndex={setFilter}
        />
        <div
          style={{
            display: "flex",
            width: "100%",
            marginLeft: "10px",
            marginRight: "10px",
            borderStyle: "solid",
            borderColor: "#22AA22",
            backgroundColor: "black",
            borderRadius: "15px",
          }}
        >
          <img
            style={{ paddingLeft: "5px", paddingTop: "7px" }}
            src="lupa.png"
          />
          <input
            id="tfsearch"
            style={{
              borderStyle: "none",
              borderColor: "transparent",
              backgroundColor: "transparent",
              color: "white",
              borderRadius: "15px",
              fontSize: "10px",
              paddingRight: "5px",
              paddingLeft: "5px",
              outline: "none",
              outlineColor: "transparent",
              accentColor: "transparent",
              lightingColor: "transparent",
              width: "100%",
            }}
            onChange={(e) => {
              setSearch(e.target.value);
            }}
            placeholder="Buscar"
          />
        </div>
        <DropdownSelectionButton_M
          title={"Categorias"}
          buttons={[
            "Aviões",
            "Barcos E Lanchas",
            "Cryptos",
            "Imóveis",
            "Tratores E Caminhões",
            "Veículos (Motors)",
          ]}
          selectedIndex={category}
          setSelectedIndex={setCategory}
        />
      </div>
      <div
        style={{
          display: "flex",
          paddingLeft: "15px",
          paddingRight: "15px",
          paddingTop: "30px",
          paddingBottom: "10px",
        }}
      >
        {buttons.map((d, idx) => {
          return (
            <button
              key={"bt_opts_" + idx}
              style={{
                color: d.isSelected() ? "white" : "gray",
                backgroundColor: "transparent",
                borderStyle: "none",
                fontSize: "10px",
              }}
              onClick={d.onClick}
            >
              {d.isSelected() ? <u>{d.label}</u> : <div>{d.label}</div>}
            </button>
          );
        })}
      </div>
      <div
        style={{
          display: "flex",
          justifyContent: "space-between",
          width: "calc(100% - 150px)",
        }}
      >
        <div></div>
        <div
          style={{
            display: "flex",
            flexWrap: "wrap",
            width: "100%",
          }}
        >
          {results.map((d, idx) => {
            return (
              <div
                key={"grid_" + idx}
                style={{ padding: "10px", paddingBottom: "20px" }}
              >
                <Card_M raffle={d.raffle} btLabel={btLabel} />
              </div>
            );
          })}
        </div>
        <div></div>
      </div>
    </div>
  );
}
