import { useState } from "react";
import "./RoundInput.css";

export interface Props {
  label: string;
  options: string[];
  values: string[];
  id: string;
  subtext?: string;
  subtextsize?: string;
}

export function RoundSelect({
  label,
  options,
  values,
  id,
  subtext,
  subtextsize,
}: Props) {
  return (
    <div
      style={{
        display: "flex",
        textAlign: "left",
        fontSize: "14px",
        paddingBottom: "20px",
        justifyContent: "space-between",
      }}
    >
      <div></div>
      <div
        style={{
          textAlign: "left",
          fontSize: "14px",
        }}
      >
        <div>{label}</div>
        <div
          style={{
            borderStyle: "solid",
            borderRadius: "25px",
            paddingTop: "10px",
            paddingBottom: "10px",
            width: "280px",
            display: "flex",
            borderWidth: "1px",
            backgroundColor: "#00000077",
          }}
        >
          <select
            id={id}
            style={{
              background: "transparent",
              backgroundColor: "transparent",
              borderStyle: "none",
              width: "100%",
              paddingLeft: "20px",
              outline: "none",
              outlineColor: "transparent",
              accentColor: "transparent",
              color: "white",
              lightingColor: "transparent",
            }}
          >
            {options.map((opt, idx) => (
              <option
                key={"rndsel_" + label + idx}
                style={{
                  color: "black",
                }}
                value={values[idx]}
              >
                {opt}
              </option>
            ))}
          </select>
        </div>
        {subtext === undefined ? (
          <div />
        ) : (
          <div
            style={{
              fontSize: subtextsize === undefined ? "8px" : subtextsize,
              paddingTop: "3px",
            }}
          >
            {subtext}
          </div>
        )}
      </div>
      <div></div>
    </div>
  );
}
