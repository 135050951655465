import sample from "../../assets/videos/video.mp4";
import icon_calendario from "../../assets/images/icon_calendario.png";
import icon_cupons from "../../assets/images/icon_cupons.png";
import icon_selecionar from "../../assets/images/icon_selecionar.png";
import trofeu from "../../assets/images/Trofeu.png";
import dollar_sign2 from "../../assets/images/Dollar_Sign2.png";

import "./PaginaInicio.css";
import "./specialeffects.css";

import { WhiteTag } from "./Components/WhiteTag";
import { WinnerCard } from "./Components/WinnerCard";
import { SwipeCarousel } from "./Components/SwipeCarousel";
import { CardProps } from "./Components/Card";
import { MainPage } from "./MainPage";
import { CampanhaRelampago } from "./Components/CampanhaRelampago";
import {
  getAllRaffles,
  getImage,
  getLatestWinners,
} from "../../services/login";
import { useEffect, useLayoutEffect, useState } from "react";
import { ModalDeposito } from "./Profile/ModalDeposito";
import { CampanhaRelampago_M } from "./Components/CampanhaRelampago_M";
import { SwipeCarousel_M } from "./Components/SwipeCarousel_M";
import { WinnerCard_M } from "./Components/WinnerCard_M";

export interface RegisterData {
  nome: string;
  sobrenome: string;
  senha: string;
  email: string;
  telefone: string;
  cpf: string;
  dia: string;
  mes: string;
  ano: string;
}

export interface Currency {
  id: number;
  name: string;
  code: string;
  isCrypto: boolean;
  enable: boolean;
}

export interface Prize {
  id: number;
  name: string;
  order: number;
  type: string;
  value: number;
  currency: Currency;
  winnerCoupon: WinnerCoupon;
}

export interface WinnerCoupon {
  id: number;
  fullName: string;
  profilePicture: ImageHeader;
  profilePictureFile: any;
  createdAt: string;
}

export interface Raffle {
  bannerImage: ImageHeader;
  bannerImageFile: any;
  id: number;
  images: ImageHeader[];
  imageFiles: (any | null)[];
  name: string;
  drawDateTime: string;
  couponQtyMin: number;
  couponQtyMax: number;
  couponPriceValue: number;
  couponPriceCurrency: Currency;
  type: string;
  campaign: string;
  description?: string;
  status: string;
  isActive: boolean;
  prizes: Prize[];
}

export interface ImageHeader {
  fileExtension: string;
  id: string;
  mimeType: string;
}

export interface AllRafflesResult {
  content: Raffle[];
  last: boolean;
  page: number;
  size: number;
  totalElements: number;
  totalPages: number;
}

export function GrabImage(header: ImageHeader, callback: (img: any) => void) {
  GrabImageById(header.id, callback);
}
export function GrabImageById(id: string, callback: (img: any) => void) {
  getImage(id, (file: any) => {
    var reader = new FileReader();
    reader.readAsDataURL(file);
    reader.onloadend = (readerEvent) => {
      if (readerEvent) {
        var content = readerEvent.target?.result;
        if (content) {
          callback(content);
        }
      }
    };
  });
}

export function FillRaffleImages(raffle: Raffle, callback: () => void) {
  var counter = raffle.status === "COMPLETED" ? -raffle.prizes.length : 0;
  GrabImage(raffle.bannerImage, (src) => {
    raffle.bannerImageFile = src;
    counter++;
    if (counter > raffle.images.length) {
      callback();
    }
  });
  if (raffle.imageFiles === undefined || raffle.imageFiles.length === 0) {
    raffle.imageFiles = [];
    for (let index = 0; index < raffle.images.length; index++) {
      raffle.imageFiles.push(null);
    }
  }
  for (let index = 0; index < raffle.images.length; index++) {
    GrabImage(raffle.images[index], (src) => {
      raffle.imageFiles[index] = src;
      counter++;
      if (counter > raffle.images.length) {
        callback();
      }
    });
  }
  if (raffle.status === "COMPLETED") {
    for (let index = 0; index < raffle.prizes.length; index++) {
      if (raffle.prizes[index].winnerCoupon?.profilePicture !== null) {
        GrabImage(raffle.prizes[index].winnerCoupon.profilePicture, (src) => {
          raffle.prizes[index].winnerCoupon.profilePictureFile = src;
          counter++;
          if (counter > raffle.images.length) {
            callback();
          }
        });
      } else {
        raffle.prizes[index].winnerCoupon.profilePictureFile = "carro.png";
        counter++;
        if (counter > raffle.images.length) {
          callback();
        }
      }
    }
  }
}

export function PaginaInicio_M() {
  const [dataCarrossel, setDataCarrossel] = useState<CardProps[]>([]);
  const [lastWinners, setLastWinners] = useState<CardProps>();
  const [menuHeight, setHeight] = useState<any>(null);
  const [depositWindow, setDepositWindow] = useState<boolean>(false);

  useLayoutEffect(() => {
    const mh = document.getElementById("header-menu")?.offsetHeight;

    setHeight(mh);
  }, [setHeight]);

  useEffect(() => {
    getAllRaffles((rest: AllRafflesResult) => {
      const data: CardProps[] = [];
      for (let index = 0; index < rest.content.length; index++) {
        const element = rest.content[index];
        FillRaffleImages(element, () => {
          data.push({
            raffle: element,
          });
          setDataCarrossel([...data]);
        });
      }
    });
    getLatestWinners((rest: any) => {
      console.log(rest);
      if (rest.content && rest.content.length > 0) {
        const element = rest.content[0];
        FillRaffleImages(element, () => {
          setLastWinners({
            raffle: element,
          });
        });
      }
    });
  }, []);

  return (
    <div style={{ backgroundColor: "black", maxWidth:"100%" }}>
      <div style={{ minHeight: "400px", minWidth: "500px" }}>
        <video
          className="videoTag"
          style={{ width: "100%" }}
          autoPlay
          loop
          muted
        >
          <source src={sample} type="video/mp4" />
        </video>
      </div>
      {depositWindow ? <ModalDeposito setModalOpen={setDepositWindow} /> : null}
      <div
        style={{
          position: "absolute",
          top: "0px",
          left: "0px",
          width: "100%",
          height: "100%",
        }}
      >
        <div
          style={{
            fontSize: 25,
            color: "white",
            paddingTop: `${50 + menuHeight}px`,
            paddingLeft: "12%",
            fontWeight: "bold",
          }}
        >
          Seu Número
        </div>
        <div
          style={{
            fontSize: 25,
            color: "white",
            paddingTop: "10px",
            paddingLeft: "12%",
            fontWeight: "bold",
            paddingBottom: "20px",
          }}
        >
          Vencedor Está Aqui
        </div>
        <div
          style={{
            display: "flex",
            justifyContent: "space-between",
          }}
        >
          <div></div>
          <div style={{ zIndex: "100" }}>
            <CampanhaRelampago_M menuHeight={menuHeight} />
          </div>
          <div></div>
        </div>
      </div>
      <div>
        <div className="part1" style={{height:"600px"}}>
          <div style={{ position: "relative" }}>
            <img
              src={dollar_sign2}
              style={{
                position: "absolute",
                top: "-50px",
                left: "0",
                pointerEvents: "none",
              }}
            />
          </div>
          <div >
            <div
              style={{
              }}
            >
              <div style={{ height: "20px" }}></div>
              <div style={{ display: "flex", justifyContent: "space-between" }}>
                <div />
                <div style={{ display: "flex" }}>
                  <img src="nLogo.png" width="32px" />
                  <div
                    style={{
                      paddingLeft: "10px",
                      fontWeight: "bold",
                      color: "white",
                      fontSize: "15px",
                    }}
                  >
                    CAMPANHAS <br /> EM DESTAQUE
                  </div>
                </div>
                <div />
              </div>
            </div>
            <div
              style={{
                height: "350px",
                paddingLeft:"calc((100% - 390px) / 3)"
              }}
            >
              <SwipeCarousel_M dados={dataCarrossel} />
            </div>
          </div>
        </div>
        <div className="part2" style={{height:"100%"}}>
          <div
            style={{
              paddingTop: "30px",
              paddingBottom: "30px",
              paddingLeft: "calc(50% - 135px)",
              fontWeight: "bold",
              fontSize: "20px",
              color: "white",
            }}
          >
            COMO FUNCIONA?
          </div>
          <div style={{ display: "flex", justifyContent: "space-around", flexWrap:"wrap" }}>
            <WhiteTag
              icon={icon_selecionar}
              title=" SELECIONAR SORTEIO"
              line="Você seleciona um sorteio do seu interesse"
            />
            <WhiteTag
              icon={icon_cupons}
              title=" COMPRAR CUPONS"
              line="Você decide a quantidade de númeroes que deseja comprar do sorteio, e paga de forma segura no nosso site."
            />
            <WhiteTag
              icon={icon_calendario}
              title=" AGUARDAR SORTEIO"
              line="Você aguarda a finalização do sorteio, ou a data marcada para o sorteio, conforme escrição, e confere o resultado."
            />
          </div>
        </div>
        <div className="part3">
        <div style={{position:"relative"}}>
          <div style={{position:"absolute", top:"0px", left:"0px"}}>
            <img src={trofeu} width={"50%"} />
          </div>
        </div>
          <div
            style={{
              paddingTop: "50px",
              display: "flex",
              justifyContent: "space-evenly"
            }}
          >
            <div
              style={{
                display: "flex",
                color: "#b1955b",
                fontSize: "30px",
                width: "60%",
                fontWeight: "bolder",zIndex:"10",
                textShadow: "-1px -1px 0 #000, 1px -1px 0 #000, -1px 1px 0 #000, 1px 1px 0 #000"
              }}
            >
              <hr
                color="#b1955b"
                style={{ width: "30%", height: "0px", marginTop: "20px" }}
              />
              LAST WINNERS
              <hr
                color="#b1955b"
                style={{ width: "30%", height: "0px", marginTop: "20px" }}
              />
            </div>
          </div>
          <div style={{ display:"flex", justifyContent:"space-evenly", paddingTop:"50px", paddingBottom:"50px", flexWrap:"wrap" }}>
              
            <div
              style={{
                position: "relative",
              }}
            >
              
              {lastWinners && lastWinners.raffle.prizes.length > 0 && (
              <div
                className="blurry"
                style={{
                  width: "290px",
                  minWidth: "290px",
                  height: "280px",
                  backgroundColor: "white",
                  padding: "10px",
                  borderStyle: "solid",
                  borderWidth: "1px",
                  borderRadius: "30px",
                  marginLeft: "30px",
                  borderColor: "gold",
                  position: "absolute",
                  top: "-0px",
                }}
              />
              )}

              {lastWinners && lastWinners.raffle.prizes.length > 0 && (
              <WinnerCard_M
                winnerName={
                  lastWinners?.raffle.prizes[0].winnerCoupon?.fullName + ""
                }
                winnerPhoto={
                  lastWinners?.raffle.prizes[0].winnerCoupon?.profilePictureFile
                }
                winnerPosition={lastWinners?.raffle.prizes[0].order + "º Lugar"}
                prizeName={lastWinners?.raffle.prizes[0].name + ""}
                prizeIcon={lastWinners?.raffle.imageFiles[0]}
                prizeCode={"XXXXXXXX"}
              />
              )}
            </div>
<div
              style={{
                position: "relative",
              }}
              >
              {lastWinners && lastWinners.raffle.prizes.length > 1 && (
                <div
                  className="blurry"
                  style={{
                    marginTop: "40px",
                    width: "232px",
                    minWidth: "232px",
                    height: "260px",
                    backgroundColor: "bbbbbb55",
                    padding: "8px",
                    borderStyle: "solid",
                    borderWidth: "1px",
                    borderRadius: "24px",
                    marginLeft: "24px",
                    borderColor: "bbbbbb",
                    position: "absolute",
                    zIndex: 0,
                  }}
                ></div>
              )}
                {lastWinners && lastWinners.raffle.prizes.length > 1 && (
                  <WinnerCard_M
                    winnerName={
                      lastWinners?.raffle.prizes[1].winnerCoupon?.fullName + ""
                    }
                    winnerPhoto={
                      lastWinners?.raffle.prizes[1].winnerCoupon
                        ?.profilePictureFile
                    }
                    winnerPosition={
                      lastWinners?.raffle.prizes[1].order + "º Lugar"
                    }
                    prizeName={lastWinners?.raffle.prizes[1].name + ""}
                    prizeIcon={lastWinners?.raffle.imageFiles[1]}
                    prizeCode={"XXXXXXXX"}
                    silver
                    small={true}
                  />
                )}
              </div>

              <div
              style={{
                position: "relative",
              }}
              >
                {lastWinners && lastWinners.raffle.prizes.length > 2 && (
                  <div
                    className="blurry"
                    style={{
                      marginTop: "40px",
                      width: "232px",
                      minWidth: "232px",
                      height: "260px",
                      backgroundColor: "bbbbbb55",
                      padding: "8px",
                      borderStyle: "solid",
                      borderWidth: "1px",
                      borderRadius: "24px",
                      marginLeft: "24px",
                      borderColor: "bbbbbb",
                      position: "absolute",
                      zIndex: 0,
                    }}
                  ></div>
                )}
                {lastWinners && lastWinners.raffle.prizes.length > 2 && (
                  <WinnerCard_M
                    winnerName={
                      lastWinners?.raffle.prizes[2].winnerCoupon?.fullName + ""
                    }
                    winnerPhoto={
                      lastWinners?.raffle.prizes[2].winnerCoupon
                        ?.profilePictureFile
                    }
                    winnerPosition={
                      lastWinners?.raffle.prizes[2].order + "º Lugar"
                    }
                    prizeName={lastWinners?.raffle.prizes[2].name + ""}
                    prizeIcon={lastWinners?.raffle.imageFiles[2]}
                    prizeCode={"XXXXXXXX"}
                    silver
                    small={true}
                  />
                )}
              </div>
          </div>
          
        </div>
        <div className="part4" style={{height:"100%", width:"100%"}}>
          <div
            style={{
              color: "white",
              fontWeight: "bolder",
              fontSize: "20px",
              paddingLeft: "calc(50% - 195px)",
              width:"80%"
            }}
          >
            Compre Crédito
          </div>
          <div
            style={{
              paddingTop: "20px",
              color: "white",
              fontSize: "12px",
              paddingLeft: "calc(50% - 195px)",
              width:"80%"
            }}
          >
            Nosso White Paper está disponível para leitura e fornece informações
            detalhadas sobre o nosso projeto, incluindo sua finalidade,
            tecnologia, recursos e como ele planeja alcançar seus objetivos.
          </div>
          <div
            style={{
              display: "flex",
              paddingTop: "20px",
              paddingBottom: "20px",
              paddingLeft: "200px",
            }}
          >
            <div></div>
            <button
              style={{
                borderRadius: "20px",
                borderColor: "transparent",
                backgroundColor: "white",
                color: "black",
                fontSize: "12px",
                fontWeight: "bold",
                paddingTop: "5px",
                paddingBottom: "5px",
                paddingLeft: "40px",
                paddingRight: "40px",
              }}
              onClick={() => {
                setDepositWindow(true);
              }}
            >
              Compre Aqui
            </button>
            <div></div>
          </div>
        </div>
      </div>
    </div>
  );
}
