import "../PaginaInicio.css";
import "../specialeffects.css";

import FlipClockCountdown from "@leenguyen/react-flip-clock-countdown";
import { useState } from "react";
import { BlackTicket } from "../Components/BlackTicket";
import { ImageCarousel } from "../Components/ImageCarousel";
import { Raffle } from "../PaginaInicio";
import { ModalCompra } from "./ModalCompra";
import { getAllCouponsFromRaffle } from "../../../services/login";
import { format } from "../../../utils/Utils";
import { Messager } from "../MainPage";
import { WinnerCard } from "../Components/WinnerCard";
import { WinnerCard_M } from "../Components/WinnerCard_M";
import { ImageCarousel_M } from "../Components/ImageCarousel_M";
import { ModalCompra_M } from "./ModalCompra_M";

interface Props {
  raffle: Raffle | null;
}

export function PaginaSorteioDetails_M({ raffle }: Props) {
  const [modalCompraOpen, setModalCompraOpen] = useState<boolean>(false);

  const [blacktickets, setBlackTickets] =
    useState<{ top: string; bot: string }[]>();
  if (blacktickets === undefined) {
    getAllCouponsFromRaffle(raffle?.id, (ans: any) => {
      const tickets = [];
      for (let index = 0; index < ans.content.length; index++) {
        const element = ans.content[index];
        tickets.push({ top: element.value, bot: element.fullName });
      }
      setBlackTickets(tickets);
    });
  }
  const [mainImage, setMainImage] = useState<string>(raffle?.imageFiles[0].src);
  const [quantity, setQuantity] = useState<number>(1);

  if (raffle === null) {
    return <div></div>;
  }
  return (
    <div style={{ backgroundColor: "white", width:"100%", overflow:"hidden" }}>      
      <div style={{ position: "relative", backgroundColor: "#00000099" }}>
        {modalCompraOpen && (
          <ModalCompra_M
            setModalOpen={setModalCompraOpen}
            raffle={raffle}
            count={quantity}
          />
        )}
      </div>
      <div style={{ backgroundImage:
            "url(" +
            raffle.bannerImageFile +
            ")",
          backgroundSize: "cover", }}>
        <div
          style={{
            width: "100%",
            backgroundColor: "#00000099",
          }}
        >
          <div
            style={{
              fontSize: 50,
              color: "white",
              paddingTop: "100px",
              paddingLeft: "12%",
              fontWeight: "bold",
            }}
          >
            {raffle.campaign + " "} SORTEADO!
          </div>
          <div
            style={{
              fontSize: 16,
              color: "white",
              paddingTop: "10px",
              paddingLeft: "12%",
              fontWeight: "bold",
              paddingBottom: "20px",
              width: "310px",
            }}
          >
            {
              "Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua."
            }
          </div>
        </div>
        <div
          style={{
            width: "100%",
          }}
        >
          {raffle.isActive ? (
            <div
              style={{
                fontSize: 20,
                color: "white",
                paddingTop: "0px",
                paddingLeft: "calc(12%)",
                fontWeight: "bold",
              }}
            >
              FALTANDO PARA O SORTEIO
            </div>
          ) : null}
          <div
            style={{
              fontSize: 16,
              color: "white",
              paddingTop: "10px",
              paddingLeft: "12%",
              fontWeight: "bold",
              paddingBottom: "20px",
              width: "400px",
            }}
          >
            <FlipClockCountdown
              to={new Date(raffle.drawDateTime).getTime()}
              labels={["DIAS", "HORAS", "MINUTOS", "SEGUNDOS"]}
              labelStyle={{
                color: "#ffee9f",
                fontSize: 10,
                fontWeight: 500,
                textTransform: "uppercase",
              }}
              digitBlockStyle={{
                color: "white",
                backgroundColor: "green",
                width: 26,
                height: 40,
                fontSize: 18,
              }}
              separatorStyle={{ color: "transparent", size: "3px" }}
              renderMap={[true, true, true, true]}
            >
              <div
                style={{
                  //backgroundColor: "red",
                  textAlign: "center",
                  fontSize: "36px",
                }}
              >
                {
                  " " //Período de compras Encerrado!
                }
              </div>
            </FlipClockCountdown>
          </div>
        </div>
        {raffle.prizes.length > 0 && (
          <div
            style={{
              backgroundImage:
                "url(" +
                require("../../../assets/images/goldBackground.png") +
                ")",
              height: "500px",
              backgroundSize: "cover",
            }}
          >
            <div
              style={{
                fontSize: "30px",
                color: "white",
                textAlign: "center",
                width: "100%",
                paddingTop: "30px",
                paddingLeft: "0px",
              }}
            >
              CUPOMS SORTEADOS
            </div>

            <div style={{ position: "relative" }}>
              <div
                style={{
                  position: "absolute",
                  top: "50px",
                  left: "calc(50% - 178px)",
                }}
              >
                <div
                  className="blurry"
                  style={{
                    width: "290px",
                    minWidth: "290px",
                    height: "280px",
                    backgroundColor: "white",
                    padding: "10px",
                    borderStyle: "solid",
                    borderWidth: "1px",
                    borderRadius: "30px",
                    marginLeft: "30px",
                    borderColor: "gold",
                    position: "absolute",
                    top: "-0px",
                  }}
                />
                {raffle.prizes.length > 0 && (
                <WinnerCard_M
                  winnerName={raffle.prizes[0].winnerCoupon?.fullName + ""}
                  winnerPhoto={
                    raffle.prizes[0].winnerCoupon?.profilePictureFile
                  }
                  winnerPosition={raffle.prizes[0].order + "º Lugar"}
                  prizeName={raffle.prizes[0].name + ""}
                  prizeIcon={raffle.imageFiles[0]}
                  prizeCode={"XXXXXXXX"}
                />
                )}
              </div>
              <div
                style={{
                  position: "absolute",
                  top: "50px",
                  left: "calc(calc(50% - 510px))",
                }}
              >
                <div
                  className="blurry"
                  style={{
                    marginTop: "40px",
                    width: "300px",
                    minWidth: "300px",
                    height: "260px",
                    backgroundColor: "bbbbbb55",
                    padding: "8px",
                    borderStyle: "solid",
                    borderWidth: "1px",
                    borderRadius: "24px",
                    marginLeft: "24px",
                    borderColor: "bbbbbb",
                    position: "absolute",
                    zIndex: 0,
                  }}
                ></div>
                {raffle.prizes.length > 1 && (
                  <WinnerCard_M
                    winnerName={raffle.prizes[1].winnerCoupon?.fullName + ""}
                    winnerPhoto={
                      raffle.prizes[1].winnerCoupon?.profilePictureFile
                    }
                    winnerPosition={raffle.prizes[1].order + "º Lugar"}
                    prizeName={raffle.prizes[1].name + ""}
                    prizeIcon={raffle.imageFiles[1]}
                    prizeCode={"XXXXXXXX"}
                    silver
                    small={true}
                    smallTxtColor={"white"}
                  />
                )}
              </div>
              <div
                style={{
                  position: "absolute",
                  top: "50px",
                  left: "calc(calc(50% + 235px))",
                }}
              >
                <div
                  className="blurry"
                  style={{
                    marginTop: "40px",
                    width: "300px",
                    minWidth: "300px",
                    height: "260px",
                    backgroundColor: "bbbbbb55",
                    padding: "8px",
                    borderStyle: "solid",
                    borderWidth: "1px",
                    borderRadius: "24px",
                    marginLeft: "24px",
                    borderColor: "bbbbbb",
                    position: "absolute",
                    zIndex: 0,
                  }}
                ></div>
                {raffle.prizes.length > 2 && (
                  <WinnerCard_M
                    winnerName={raffle.prizes[2].winnerCoupon?.fullName + ""}
                    winnerPhoto={
                      raffle.prizes[2].winnerCoupon?.profilePictureFile
                    }
                    winnerPosition={raffle.prizes[2].order + "º Lugar"}
                    prizeName={raffle.prizes[2].name + ""}
                    prizeIcon={raffle.imageFiles[2]}
                    prizeCode={"XXXXXXXX"}
                    silver
                    small={true}
                    smallTxtColor={"white"}
                  />
                )}
              </div>
            </div>
          </div>
        )}
        <div style={{ backgroundColor: "#FCF7F0", color: "black" }}>
          <div
            style={{
              display: "flex",
              flexWrap: "wrap",
              marginLeft: "30px",
              marginRight: "30px",
              justifyContent: "space-between",
            }}
          >
            <div
              style={{
                flexGrow: "10",
                paddingTop: "20px",
              }}
            >
              <div>SORTEIO:</div>
              <div style={{ display: "flex", flexGrow: "100" }}>
                <div
                  style={{
                    fontSize: "20px",
                    fontWeight: "bold",
                  }}
                >
                  {raffle.campaign + " SORTEADO!"}
                </div>
                <div>{/*<Calendario/>*/}</div>
              </div>
              <div
                style={{ paddingTop: "5px", height: "300px", overflow: "auto" }}
              >
                {raffle.description}
              </div>
              <div >
                <div style={{ display:"flex", justifyContent:"space-between" }}> <div />
                <div
                  style={{
                    backgroundColor: "green",
                    color: "white",
                    padding: "10px",
                    borderRadius: "15px",
                    borderStyle: "none",
                    width: "20%",
                    minWidth: "180px",
                    marginRight: "10px",
                    maxHeight: "50px",
                  }}
                >
                  <div style={{ fontSize: "13px", textAlign: "center" }}>
                    DATA FINAL PARA COMPRAS
                  </div>
                  <div
                    style={{
                      fontSize: "30px",
                      fontWeight: "bold",
                      textAlign: "center",
                    }}
                  >
                    {raffle.drawDateTime.substring(8, 10) +
                      "/" +
                      raffle.drawDateTime.substring(5, 7) +
                      "/" +
                      raffle.drawDateTime.substring(0, 4)}
                  </div>
                </div>
                <div />
                </div>
                <div>
                  <div
                    style={{ display: "flex", justifyContent: "space-between" }}
                  >
                    <div style={{ fontSize: "20px", fontWeight: "bold" }}>
                      {(raffle.couponQtyMin / raffle.couponQtyMax) * 100}% dos
                      cupons vendidos
                    </div>
                    <div style={{ fontSize: "20px" }}>
                      {raffle.couponQtyMin} de {raffle.couponQtyMax}
                    </div>
                  </div>
                  <progress
                    id="cuponsVendidos"
                    value={raffle.couponQtyMin}
                    max={raffle.couponQtyMax}
                    color="green"
                    style={{
                      accentColor: "green",
                      width: "100%",
                      height: "40px",
                    }}
                  />
                  <div>
                    Dia do Sorteio: O Sorteio será feito 72h depois de todos os
                    cupons serem vendidos
                  </div>
                </div>
              </div>
              <div style={{ display: "flex" }}>
                <div style={{ width: "100%", paddingTop: "4px" }}>
                  <hr style={{ borderWidth: "2px", borderStyle: "solid" }} />
                </div>
                <div
                  style={{
                    paddingRight: "30px",
                    paddingLeft: "30px",
                    fontSize: "20px",
                    fontWeight: "bold",
                  }}
                >
                  Prêmios
                </div>
                <div style={{ width: "100%", paddingTop: "4px" }}>
                  <hr style={{ borderWidth: "2px", borderStyle: "solid" }} />
                </div>
              </div>
              <div style={{  }}>
                <div></div>
                {raffle.prizes.length > 0 ? (
                  <div style={{ textAlign: "center", width: "100%" }}>
                    <div>{raffle.prizes[0].order}° lugar</div>
                    <div>Prêmio: {raffle.prizes[0].name}</div>
                    <div>
                      Valor: {format(raffle.prizes[0].value, "Decimal")}
                    </div>
                  </div>
                ) : null}
                {raffle.prizes.length > 1 ? (
                <div style={{ width: "100%", paddingTop: "4px" }}>
                  <hr style={{ borderWidth: "2px", borderStyle: "solid" }} />
                </div>
                ) : null}
                {raffle.prizes.length > 1 ? (
                  <div style={{ textAlign: "center", width: "100%" }}>
                    <div>{raffle.prizes[1].order}° lugar</div>
                    <div>Prêmio: {raffle.prizes[1].name}</div>
                    <div>
                      Valor: {format(raffle.prizes[1].value, "Decimal")}
                    </div>
                  </div>
                ) : null}
                {raffle.prizes.length > 2 ? (
                <div style={{ width: "100%", paddingTop: "4px" }}>
                  <hr style={{ borderWidth: "2px", borderStyle: "solid" }} />
                </div>
                ) : null}
                {raffle.prizes.length > 2 ? (
                  <div style={{ textAlign: "center", width: "100%" }}>
                    <div>{raffle.prizes[2].order}° lugar</div>
                    <div>Prêmio: {raffle.prizes[2].name}</div>
                    <div>
                      Valor: {format(raffle.prizes[2].value, "Decimal")}
                    </div>
                  </div>
                ) : null}
                <div></div>
              </div>
            <div
              style={{
                maxWidth: "390px",
                flexGrow: "10",
              }}
            >
              <ImageCarousel_M imagens={raffle?.imageFiles} />
            </div>
            </div>
          </div>
          <hr style={{ width: "Calc(100% - 60px)" }} />
          <div style={{ display: "flex", flexWrap: "wrap" }}>
            <div
              style={{
                width: "300px",
                margin: "30px",
                padding: "30px",
                backgroundColor: "#D9D9D9",
                borderRadius: "30px",
                overflow:"hidden"
              }}
            >
              <div style={{ fontSize: "13px", color:"gray" }}>Comprar Cupom</div>
              <div style={{ fontWeight: "bold", fontSize: "20px" }}>
                {raffle.campaign} Sorteado
              </div>
              <div style={{ display: "flex" }}>
                <div
                  style={{
                    fontWeight: "bold",
                    color: "green",
                    fontSize: "20px",
                  }}
                >
                  RS {format(raffle.couponPriceValue, "Decimal")}
                </div>
                <div style={{ paddingLeft: "10px", fontSize: "13px",paddingTop: "7px", color:"gray" }}>
                  Valor por unidade
                </div>
              </div>
              <hr />
              <div style={{ fontSize: "13px" }}>Quantidade</div>

              <div
                style={{
                  display: "flex",
                  justifyContent: "space-between",
                }}
              >
                <div
                  style={{
                    display: "flex",
                    backgroundColor: "white",
                    justifyContent: "space-between",
                    width: "50%",
                    marginRight: "5px",
                    fontSize: "20px",
                    borderRadius: "25px",
                  }}
                >
                  <div
                    style={{
                      width: "50px",
                      textAlign: "center",
                      fontSize: "20px",
                      fontWeight: "bolder",
                      cursor: "pointer",
                    }}
                    onClick={() => {
                      if (quantity > 1) {
                        setQuantity(quantity - 1);
                      }
                    }}
                  >
                    -
                  </div>
                  <div>{quantity}</div>
                  <div
                    style={{
                      width: "50px",
                      textAlign: "center",
                      fontSize: "20px",
                      fontWeight: "bolder",
                      cursor: "pointer",
                    }}
                    onClick={() => {
                      setQuantity(quantity + 1);
                    }}
                  >
                    +
                  </div>
                </div>
                <div
                  style={{
                    backgroundColor: raffle.isActive ? "green" : "gray",
                    color: "white",
                    justifyContent: "space-between",
                    width: "50%",
                    marginLeft: "5px",
                    fontSize: "15px",
                    padding: "5px",
                    borderRadius: "45px",
                    textAlign: "center",
                  }}
                  onClick={() => {
                    raffle.isActive
                      ? setModalCompraOpen(true)
                      : Messager.message(
                          "Aviso",
                          "Infelizmente este sorteio já acabou!"
                        );
                  }}
                >
                  Comprar Cupons
                </div>
              </div>
              <div
                style={{
                  backgroundColor: "gray",
                  color: "white",
                  justifyContent: "space-between",
                  marginTop: "20px",
                  fontSize: "14px",
                  padding: "0px",
                  borderRadius: "45px",
                  textAlign: "center",
                }}
              >
                Valor Total:{" "}
                {format(raffle.couponPriceValue * quantity, "Decimal")}
              </div>
              <div style={{fontSize:"10px", color:"gray", marginTop:"5px"}}>
                *Favor conferir as regras do sorteio antes de efetuar a compra.
              </div>
            </div>
            <div
              style={{
                flexGrow: "100",
                margin: "30px",
                padding: "30px",
                backgroundColor: "#D9D9D9",
                borderRadius: "30px",
                height: "448px",
                overflow: "auto",
              }}
            >
              <div style={{ display: "flex", paddingBottom: "20px" }}>
                <div style={{ width: "100%", paddingTop: "4px" }}>
                  <hr style={{ borderWidth: "2px", borderStyle: "solid" }} />
                </div>
                <div
                  style={{
                    fontSize: "16px",
                    fontWeight: "bold",
                    textAlign: "center",
                    minWidth: "150px",
                  }}
                >
                  Meus Cupons
                </div>
                <div style={{ width: "100%", paddingTop: "4px" }}>
                  <hr style={{ borderWidth: "2px", borderStyle: "solid" }} />
                </div>
              </div>
              <div
                style={{
                  display: "flex",
                  flexWrap: "wrap",
                  justifyContent: "space-evenly",
                }}
              >
                {blacktickets?.map((ticket, idx) => {
                  return (
                    <div key={"blackTicket" + idx} style={{ margin: "0px" }}>
                      <BlackTicket
                        topline={ticket.top}
                        bottomline={ticket.bot}
                      />
                    </div>
                  );
                })}
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
