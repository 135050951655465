import { useState } from "react";
import { RoundInput } from "../Components/RoundInput";
import { RoundInputUpload } from "../Components/RoundInputUpload";
import { requestPrize } from "../../../services/login";
import { Messager } from "../MainPage";


interface Props {
  raffleId: string;
}

export function PaginaSorteioCheckout({raffleId}: Props) {
  const [file, setFile] = useState<any>();
  const [premio, setPremio] = useState<boolean>(true);
  
  return (
    <div
      style={{
        paddingTop: window.innerWidth<1000?"70px":"150px",
        width: "100%",
        textAlign: "left",
        fontSize: "30px",
        backgroundImage:
          "url(" +
          require("../../../assets/images/green_background2.png") +
          ")",
        backgroundSize: "cover",
      }}
    >
      <div style={{backgroundColor:"white", color:"black"
        ,paddingLeft:"50px", paddingRight:"50px"}}>
      <div></div>
        <div style={{fontSize:"30px"}}><b>Contemplar Prêmio</b></div>
        <div style={{fontSize:"20px"}}>Retirada do Prêmio</div>
        <div style={{display:"flex", fontSize:"20px", flexWrap:"wrap"}}>
          <div style={{ fontSize: "18px", display: "flex", paddingRight:"20px", paddingTop:"5px"}} onClick={()=>{
            setPremio(!premio);
          }}>
            <img src={premio?"CheckboxON.png":"CheckboxOFF.png"} width={25} height={25} style={{paddingTop:"5px"}} />            
            <div style={{ fontSize:"18px", paddingLeft:"10px",borderColor:"green", borderWidth:"1px", borderStyle:"solid", borderRadius:"7px", width:"150px"}}>Prêmio</div>
          </div>
          <div style={{ fontSize: "18px", display: "flex", paddingRight:"20px", paddingTop:"5px"}} onClick={()=>{
            setPremio(!premio);
          }}>
            <img src={!premio?"CheckboxON.png":"CheckboxOFF.png"} width={25} height={25} style={{paddingTop:"5px"}} />   
            <div style={{ fontSize:"18px", paddingLeft:"10px",borderColor:"green", borderWidth:"1px", borderStyle:"solid", borderRadius:"7px", width:"150px"}}>Valor em dinheiro</div>
          </div>
        </div>
        <div>Nome e Informações</div>
        <div style={{ width:"100%"}}><RoundInput label={"Primeiro Nome"} type={"text"} id={"nome"} width="100%" borderColor="green" backgroundColor="white" fontColor="black" /></div>
        <div><RoundInput label={"Sobrenome"} type={"text"} id={"sobrenome"} width="100%" borderColor="green" backgroundColor="white" fontColor="black"  /></div>
        <div style={{display:"flex", width:"100%"}}>
          <div style={{width:"100%", paddingRight:"5px"}}><RoundInput label={"CPF"} type={"cpf"} id={"cpf"} width="100%" borderColor="green" backgroundColor="white" fontColor="black" /></div>
          <div style={{width:"100%", paddingLeft:"5px"}}><RoundInput label={"Email"} type={"email"} id={"email"}  width="100%" borderColor="green" backgroundColor="white" fontColor="black"/></div>
        </div>
        <div><RoundInput label={"Telefone"} type={"text"} id={"phone"} width="100%" borderColor="green" backgroundColor="white"  fontColor="black"/></div>
        <div><RoundInput label={"Endereço"} type={"text"} id={"endereco"} width="100%" borderColor="green" backgroundColor="white"  fontColor="black"/></div>
        <div style={{display:"flex", width:"100%"}}>
          <div style={{width:"100%", paddingRight:"5px"}}><RoundInput label={"Número"} type={"text"} id={"numero"} width="100%" borderColor="green" backgroundColor="white" fontColor="black" /></div>
          <div style={{width:"100%", paddingLeft:"5px"}}><RoundInput label={"CEP"} type={"text"} id={"cep"}  width="100%" borderColor="green" backgroundColor="white" fontColor="black"/></div>
        </div>
        <div><RoundInputUpload label={"Comprovante"} type={"file"} id={"comprovante"} labelBtn={"Anexar Documento"} subtext={"Anexar comprovante de acordo com o sorteio"} width="100%" borderColor="green" backgroundColor="white" onChange={function (val): void {
                setFile(val?.target?.files[0]);
              }
        } /></div>
        <div></div>
        <div style={{display:"flex", justifyContent:"space-between", paddingBottom:"50px"}}>
          <div></div>
          <div
            style={{
              backgroundColor: "green",
              color: "white",
              justifyContent: "space-between",
              width: "200px",
              fontSize: "20px",
              padding: "10px",
              borderRadius: "45px",
              textAlign: "center",
              cursor:"pointer",
            }}
            onClick={() => {
              const nome = (document.getElementById("nome") as HTMLInputElement).value;
              const sobrenome = (document.getElementById("sobrenome") as HTMLInputElement).value;
              const email = (document.getElementById("email") as HTMLInputElement).value;
              const phone = (document.getElementById("phone") as HTMLInputElement).value;
              const cpf = (document.getElementById("cpf") as HTMLInputElement).value;
              const rua = (document.getElementById("endereco") as HTMLInputElement).value;
              const nro = (document.getElementById("numero") as HTMLInputElement).value;
              const cep = (document.getElementById("cep") as HTMLInputElement).value;              
              if(nome&&sobrenome&&email&&phone&&rua&&nro&&cep&&cpf&&file){
                const address = rua +" Nro:"+nro+" CEP:"+cep;
                const dados = {
                  inCash: !premio,
                  name: nome,
                  lastName: sobrenome,
                  email: email,
                  phoneNumber: phone,
                  cpf: cpf,
                  location_address: rua,
                  location_number : nro,
                  location_cep : cep
                }
                requestPrize(raffleId,file,dados, (ans:any)=>{
                  if(ans.status==="PENDING"){
                    Messager.message("Pronto!","Pedido realizado com sucesso!",false,()=>{
                      window.location.href = window.location.href.replace("pag=10","pag=8");
                    });
                  }else{
                    Messager.message("Erro!?","Por favor contate o nosso time para verificarmos o ocorrido.",false,()=>{
                      window.location.href = window.location.href.replace("pag=10","pag=8");
                    });
                  }
                });
              }else{
                Messager.message("Atenção!", "É necessário preencher todos os campos!");
              }
              /*
              searchRafflesByID(myid, (element: any) => {
                
              });*/
            }}
          >
              Continue
          </div>
          <div></div>
        </div>
      </div>
    </div>
  );
}
